import React, { useState } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"

import MobileMenu from "../MobileMenu/MobileMenu"
import HeaderMenu from "./HeaderMenu"
import HeaderSettings from "./HeaderSettings"
import HeaderLogo from "../Icons/HeaderLogo"

const Header: React.FC<{
  mobileMenu: boolean
  setMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ mobileMenu, setMobileMenu }) => {
  const [focusSkip, setFocusSkip] = useState(false)

  const handleMobileMenu = (value: boolean) => {
    setMobileMenu(value)
  }

  const handleFocusSkip = () => {
    setFocusSkip(!focusSkip)
  }
  const setFocus = () => {
    document.getElementById("main").focus()
  }

  return (
    <header
      aria-label="site header"
      css={[
        tw`bg-gradient-to-b from-blue-200 to-blue-300 text-white w-full h-16 xl:h-28 relative z-50`,
        mobileMenu && tw`fixed`,
      ]}
    >
      <Link
        to="#main"
        css={[
          tw`text-extraDarkGray text-sm font-semibold absolute left-36 xl:left-18 -top-full bg-sand px-5 py-2 rounded-b-lg underline transition-top duration-300 shadow-dropdown`,
          focusSkip && tw`top-0`,
        ]}
        onClick={setFocus}
        onFocus={handleFocusSkip}
        onBlur={handleFocusSkip}
        style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
      >
        Skip to Main Content
      </Link>
      <Link
        to="/"
        css={[
          tw`block absolute top-0 left-8 xl:left-1/2 xl:transform xl:-translate-x-1/2 flex items-center justify-center shadow-lg bg-white w-26 h-24 xl:w-36 xl:h-34 rounded-b-3xl z-30`,
        ]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
        onClick={() => handleMobileMenu(false)}
      >
        <HeaderLogo styling={tw`w-18 h-18 xl:w-24 xl:h-24 m-auto`} />
      </Link>
      <div
        css={[
          tw`px-8 xl:px-12 2xl:px-16 m-auto max-w-8xl flex flex-col justify-between h-full`,
        ]}
      >
        <HeaderSettings />
        <HeaderMenu
          mobileMenu={mobileMenu}
          handleMobileMenu={() => handleMobileMenu(!mobileMenu)}
          setMobileMenu={setMobileMenu}
        />
      </div>
      <MobileMenu
        mobileMenu={mobileMenu}
        handleMobileMenu={() => handleMobileMenu(!mobileMenu)}
      />
    </header>
  )
}

export default Header
