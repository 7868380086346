import React from "react"

const NightHeavyRain: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      css={[styling]}
      fill="#fff"
    >
      <path
        d="M4.26,16.93c0,0.66,0.12,1.28,0.38,1.88s0.59,1.11,1.02,1.55c0.43,0.43,0.94,0.79,1.53,1.05s1.21,0.42,1.87,0.45
	c0.11,0,0.17-0.06,0.17-0.17v-1.34c0-0.11-0.06-0.17-0.17-0.17c-0.87-0.06-1.6-0.41-2.19-1.03c-0.59-0.62-0.89-1.37-0.89-2.22
	c0-0.84,0.28-1.57,0.85-2.19c0.57-0.62,1.26-0.97,2.1-1.04l0.53-0.06c0.13,0,0.2-0.06,0.2-0.19l0.06-0.51
	c0.11-1.09,0.56-1.99,1.37-2.71s1.76-1.08,2.86-1.08c1.09,0,2.05,0.36,2.85,1.07c0.81,0.72,1.27,1.61,1.38,2.69l0.07,0.58
	c0,0.12,0.06,0.18,0.19,0.18h1.6c0.9,0,1.67,0.32,2.32,0.96s0.97,1.4,0.97,2.29c0,0.86-0.3,1.6-0.89,2.22
	c-0.59,0.62-1.33,0.97-2.19,1.03c-0.13,0-0.2,0.06-0.2,0.17v1.34c0,0.11,0.07,0.17,0.2,0.17c1.34-0.06,2.47-0.57,3.39-1.51
	c0.92-0.95,1.38-2.09,1.38-3.42c0-0.72-0.14-1.38-0.42-1.99c0.78-0.94,1.17-2.06,1.17-3.36c0-0.94-0.23-1.81-0.7-2.62
	c-0.47-0.81-1.11-1.45-1.91-1.92s-1.68-0.71-2.62-0.71c-1.56,0-2.85,0.58-3.88,1.73c-0.88-0.43-1.78-0.65-2.7-0.65
	c-1.41,0-2.66,0.44-3.75,1.32s-1.79,2-2.1,3.38c-1.1,0.26-2.01,0.83-2.73,1.73C4.62,14.76,4.26,15.78,4.26,16.93z M9.75,23.61
	c0,0.4,0.22,0.66,0.65,0.78c0.21,0.07,0.42,0.05,0.63-0.06c0.21-0.11,0.35-0.28,0.41-0.5l1.5-5.73c0.06-0.22,0.03-0.43-0.09-0.63
	c-0.12-0.2-0.3-0.33-0.54-0.4c-0.22-0.07-0.43-0.05-0.63,0.07s-0.33,0.29-0.39,0.52l-1.5,5.7C9.76,23.47,9.75,23.55,9.75,23.61z
	 M12.34,26.66c0,0.12,0.03,0.24,0.08,0.37c0.1,0.21,0.27,0.35,0.51,0.43c0.02,0,0.06,0,0.1,0.01s0.08,0.01,0.11,0.01s0.06,0,0.09,0
	c0.43,0,0.68-0.22,0.76-0.66l2.3-8.74c0.07-0.22,0.05-0.43-0.06-0.63c-0.11-0.2-0.28-0.33-0.5-0.4c-0.24-0.07-0.47-0.05-0.68,0.07
	s-0.33,0.29-0.38,0.52l-2.31,8.75C12.35,26.49,12.34,26.58,12.34,26.66z M16.5,23.6c0,0.16,0.05,0.31,0.16,0.47
	c0.11,0.16,0.26,0.26,0.45,0.32c0.06,0.02,0.14,0.03,0.23,0.03c0.17,0,0.33-0.05,0.49-0.14c0.16-0.09,0.26-0.24,0.32-0.45l1.5-5.73
	c0.08-0.21,0.05-0.41-0.07-0.62c-0.12-0.21-0.29-0.34-0.52-0.41c-0.24-0.07-0.46-0.05-0.66,0.07c-0.2,0.12-0.32,0.29-0.36,0.52
	l-1.5,5.7C16.51,23.47,16.5,23.55,16.5,23.6z M18.03,9.08c0.67-0.67,1.49-1,2.48-1c0.98,0,1.81,0.34,2.49,1.02
	c0.69,0.68,1.03,1.51,1.03,2.48c0,0.63-0.17,1.24-0.52,1.85C22.56,12.48,21.4,12,20.02,12h-0.31C19.43,10.83,18.87,9.86,18.03,9.08z
	"
      />
    </svg>
  )
}

export default NightHeavyRain
