import React from "react"

const HeaderLogo: React.FC<{ styling: any }> = ({ styling }) => {
  return (
    <svg
      css={[styling]}
      aria-label="BHM"
      viewBox="0 0 97 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path id="4dtaczs3xa" d="M0 0h26.58v37.857H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#007F7B"
          d="M77.857 0 3.571 34.37l50.565-19.308-38.357 19.38 42.838-16.92-30.8 16.92L62.454 20.31 39.643 34.226l16.813-6.525V41.43z"
        />
        <g transform="translate(0 42.143)">
          <mask id="obmigvezpb" fill="#fff">
            <use href="#4dtaczs3xa" />
          </mask>
          <path
            d="M13.124 32.45c-3.949 0-6.673-3.57-6.673-8.81 0-5.13 2.724-8.642 6.673-8.642 3.948 0 6.506 3.457 6.506 8.753s-2.558 8.698-6.506 8.698m1.612-23.194c-3.114 0-5.894 1.394-7.952 3.848V0H0v37.133h6.784v-2.9c2.614 2.676 4.67 3.624 7.842 3.624 6.95 0 11.955-5.91 11.955-14.217 0-8.14-5.116-14.385-11.845-14.385"
            fill="#231F20"
            mask="url(#obmigvezpb)"
          />
        </g>
        <path
          d="M43.542 51.398c-3.17 0-5.505 1.338-7.895 4.517V42.143h-6.84v37.133h6.84v-14.33c0-5.073 1.89-7.583 5.726-7.583 3.171 0 4.95 2.398 4.95 6.747v15.166h6.729v-16.56c0-3.456-.39-5.24-1.502-7.08-1.668-2.62-4.615-4.238-8.008-4.238M87.642 51.398c-3.782 0-6.674 1.728-8.564 5.018-1.78-3.4-4.338-5.018-8.008-5.018-3.503 0-6.005 1.395-7.562 4.126v-3.345h-6.84v27.097h6.84V63.44c0-3.791 1.945-6.077 5.116-6.077 3.002 0 4.504 2.063 4.559 6.077v15.835h6.785V63.44c0-3.791 1.945-6.133 5.116-6.133 3.057 0 4.504 2.007 4.56 6.133v15.835h6.785v-16.17c0-4.46-.39-6.188-1.67-8.195-1.445-2.118-4.17-3.513-7.117-3.513z"
          fill="#231F20"
        />
      </g>
    </svg>
  )
}

export default HeaderLogo
