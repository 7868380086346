import React, { useState, useContext, useEffect, useRef } from "react"
import tw from "twin.macro"
import useOpenDropdown from "../../hooks/useOpenDropdown"
import MenuArrow from "../Icons/MenuArrow"
import { PageProps } from "gatsby"
import { LanguageContext } from "../../context/Language"
import { AcceptedLanguage } from "../../context/Language/language.d"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"

const HeaderSettingsLanguage: React.FC = () => {
  const ref = useRef(null)
  const { open, setOpen, hover, setHover } = useOpenDropdown(ref)
  const { language, languageOptions, setLanguage } = useContext(LanguageContext)

  const handleMouseDown = () => {
    setOpen(!open)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleMouseDown()
    }
  }

  const handleBlur = (e: React.FocusEvent) => {
    if (!ref.current.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }

  const handleChangeLanguage = (languageCode: AcceptedLanguage) => {
    setLanguage(languageCode)
    setOpen(false)
  }

  const languageText = "Language"
  const [text, setText] = useState({ languageText })

  useEffect(() => {
    if (language === "en") {
      setText({ languageText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([languageText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div
      ref={ref}
      css={[tw`tracking-tight text-3.75`]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <button
        type="button"
        css={[
          tw`p-2 transition-colors duration-500 flex items-center transition-all font-light`,
          (open || hover) && tw`bg-sand text-grayBlack`,
          open ? tw`rounded-t` : tw`rounded`,
        ]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
        aria-label={`${open ? "close" : "open"} language dropdown`}
        aria-expanded={open}
        aria-controls="languageDropdown"
        onMouseDown={handleMouseDown}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      >
        {text?.languageText}:
        <span css={[tw`ml-1 font-semibold uppercase`]}>{language}</span>
        <MenuArrow
          type={open ? "up" : "down"}
          color={hover || open ? "#4b4143" : "#9ed8d5"}
        />
      </button>
      <div
        id="languageDropdown"
        css={[
          tw`absolute z-50 bg-sand w-60 shadow-dropdown overflow-hidden transition-max-h ease-max-h-hide duration-max-h-hide max-h-0`,
          open && tw`max-h-screen ease-max-h-show duration-max-h-show`,
        ]}
      >
        {languageOptions?.map(({ label, value }) => (
          <div key={value} css={[tw`w-full px-5 first-of-type:pt-2 last:pb-2`]}>
            <button
              type="button"
              css={[
                tw`block text-grayBlack h-6 my-4 text-left hover:underline focus:underline`,
              ]}
              onClick={() => handleChangeLanguage(value)}
              onBlur={handleBlur}
              style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
              tabIndex={!open && -1}
            >
              {label}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HeaderSettingsLanguage
