import React from "react"
import tw from "twin.macro"

const Search: React.FC<{
  styling?: any
  color?: string
}> = ({ styling, color, ...remainingProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0.87 0 21.99 22"}
      css={[tw`transition-colors fill-current	`, styling]}
      aria-label="search"
      {...remainingProps}
    >
      <path
        d={
          "M16.934 13.406a8.7 8.7 0 0 0 1.363-4.68c0-4.819-3.9-8.726-8.71-8.726C4.77 0 .87 3.907.87 8.726c0 4.818 3.9 8.725 8.71 8.725a8.69 8.69 0 0 0 4.737-1.398l.395-.275L20.932 22l1.93-1.965-6.215-6.222.287-.407zm-2.426-9.764a7.028 7.028 0 0 1 2.07 5 7.028 7.028 0 0 1-2.07 5.001 7.022 7.022 0 0 1-4.999 2.071 7.022 7.022 0 0 1-4.998-2.07 7.028 7.028 0 0 1-2.07-5.001c0-1.889.735-3.665 2.07-5A7.022 7.022 0 0 1 9.509 1.57c1.888 0 3.664.736 4.999 2.071z"
        }
      />
    </svg>
  )
}

export default Search
