import React from "react"
import { GlobalStyles as TWGlobalStyles } from "twin.macro"

import "@fontsource/mulish/200.css"
import "@fontsource/mulish/300.css"
import "@fontsource/mulish"
import "@fontsource/mulish/500.css"
import "@fontsource/mulish/600.css"
import "@fontsource/mulish/700.css"
import "@fontsource/mulish/800.css"

const GlobalStyles: React.FC = () => {
  return (
    <>
      <TWGlobalStyles />
    </>
  )
}
export default GlobalStyles
