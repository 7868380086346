import React, { useState, useRef, useContext, useEffect } from "react"
import tw from "twin.macro"
import { Link } from "gatsby"

import ListDropdown from "../Icons/ListDropdown"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
import MobileLink from "./MobileLink"
const {getStringValuesArray, mapValuesToObject} = require("../../helpers")

const MenuDropdown: React.FC<{
  title: string
  options: any
  handleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ title, options, handleMobileMenu }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const handleMouseDown = () => {
    setOpen(!open)
  }

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleMouseDown()
    }
  }

  const handleBlur = e => {
    // if (!ref.current.contains(e.relatedTarget)) {
    //   setOpen(false)
    // }
  }

  return (
    <div css={[tw`w-full flex flex-col`]} ref={ref}>
      <button
        key={title}
        css={[tw`py-4 pl-8 flex flex-row text-xl border-t border-lightGray`]}
        aria-label={`${open ? "close" : "open"} ${title} dropdown`}
        aria-expanded={open}
        aria-controls={`${title}Dropdown`}
        style={{ outlineColor: "#AED1D3", outlineOffset: "0" }}
        onMouseDown={handleMouseDown}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      >
        <span css={[tw`text-left`]}>{text?.title}</span>
        <ListDropdown
          styling={[
            tw`absolute right-4 -mt-2 h-10 transition-transform duration-200 ease-linear`,
            open && tw`-rotate-180 translate-y-2`,
          ]}
        />
      </button>
      <div
        id={`${title}Dropdown`}
        css={[
          tw`mx-11 overflow-hidden max-h-0 transition-all duration-500 ease-in-out`,
          open && tw`max-h-screen`,
        ]}
      >
        {options?.map(({ label, path }, index) => (
          <MobileLink key={index} label={label} path={path} handleBlur={handleBlur} handleMobileMenu={handleMobileMenu} open={open} />
        ))}
      </div>
    </div>
  )
}

export default MenuDropdown

