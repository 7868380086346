import React, {useState, useContext, useEffect} from "react"
import tw from "twin.macro"
import { Link } from "gatsby"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
const {getStringValuesArray, mapValuesToObject} = require("../../helpers")

import MenuDropdown from "./MenuDropdown"
import useHeaderMenuItems from "../../hooks/useHeaderMenuItems"
import useFooterMenuItems from "../../hooks/useFooterMenuItems"

interface Props {
  handleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const MenuContent: React.FC<Props> = ({handleMobileMenu}) => {
  const headerMenuItems = useHeaderMenuItems()
  const footerMenuItems = useFooterMenuItems()

  return (
    <>
      {headerMenuItems?.map(({ label, childItems }) => {
        return (
          <MenuDropdown key={label} title={label} options={childItems?.nodes} handleMobileMenu={handleMobileMenu} />
        )
      })}
      {footerMenuItems?.map(({ node }, index) => (
        <MobileLink key={index} path={node.path} label={node.label} handleMobileMenu={handleMobileMenu} />
      ))}
    </>
  )
}

export default MenuContent

const MobileLink:React.FC<{
  path: string;
  label: string;
  handleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ path, handleMobileMenu, label }) => {
  const [text, setText] = useState({ label })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ label })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([label])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div
          css={[tw`py-4 pl-8 flex flex-row text-lg border-t border-lightGray`]}
        >
          <Link
            to={path}
            onClick={() => handleMobileMenu(false)}
            style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
          >
            {text?.label}
          </Link>
        </div>
  )
}