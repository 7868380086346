import { graphql, useStaticQuery } from "gatsby"

const useSocialLinks = () => {
  const socialLinks = useStaticQuery(graphql`
    {
      allWp {
        nodes {
          siteSettings {
            socialLinks {
              facebook
              headline
              instagram
              twitter
            }
          }
        }
      }
    }
  `)

  const data = socialLinks.allWp.nodes[0].siteSettings.socialLinks
  const socials = Object.keys(data).filter(key => key !== "headline")

  return {
    headline: data.headline,
    socials: socials.map(key => ({
      name: key,
      url: data[key],
    })),
  }
}

export default useSocialLinks
