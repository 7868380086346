import React, { useState } from "react"
import tw from "twin.macro"
import { SearchBarProps } from "./Search.d"
import SearchButton from "./SearchButton"

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  inputName,
  setSearchQuery,
  handleBlur,
  ...remainingProps
}) => {
  const [searchInput, setSearchInput] = useState("")

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setSearchQuery(searchInput)
  }

  const handleOnKeyDown = e => {
    if (e.key === "Tab") {
      handleBlur(e)
    }
  }

  return (
    <form {...remainingProps}>
      <div
        role="search"
        css={tw`flex flex-row justify-center p-1 focus-within:border-2 focus-within:border-blue-150 focus-within:rounded`}
      >
        <input
          type="search"
          placeholder={placeholder}
          css={[tw`w-full pl-4 rounded-l-md focus:outline-none`]}
          value={searchInput}
          name={inputName}
          onChange={e => setSearchInput(e.target.value)}
        />
        <SearchButton
          handleClick={handleClick}
          handleOnKeyDown={handleOnKeyDown}
        />
      </div>
    </form>
  )
}

export default SearchBar
