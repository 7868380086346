import React, { useEffect, useState } from "react"
import tw from "twin.macro"

import {
  getWeatherConditions,
  weatherIconOptions,
} from "./HeaderMenuWeather.helpers"

const HeaderMenuWeather: React.FC<{ time: string }> = ({ time }) => {
  const [temp, setTemp] = useState(null)
  const [iconName, setIconName] = useState("")

  const getWeather = async () => {
    try {
      const res = await fetch(`${process.env.GATSBY_BAA_API_URL}/weather`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.GATSBY_BAA_API_KEY
        }
      })
      const data = await res.json()
      if(data?.main) {
        const { tempInFarenheit, weatherIcon: { name }} = getWeatherConditions(data)
        setTemp(tempInFarenheit)
        setIconName(name)
      }
    } catch(err) {
      console.log(err.message)
    }
  }

  const checkWeatherEveryHour = () => setInterval(() => getWeather(), 3600000)

  useEffect(() => {
    getWeather()
    checkWeatherEveryHour()
  }, [])

  return (
    <div css={[tw`hidden xl:flex items-center pl-2`]}>
      <p>
        {time}
        {temp && (
          <>
          <span css={[tw`mx-3`]}>|</span>
          <span>{temp}°</span>
          <span css={[tw`mx-3`]}>|</span>
          </>
        )}
      </p>
      {iconName &&
        weatherIconOptions?.map(({ name, icon: Icon, id }) => {
          if (name === iconName) {
            return <Icon key={id} styling={tw`flex pl-1 w-8 h-8`} />
          }
        })}
    </div>
  )
}
export default HeaderMenuWeather
