import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { Link } from "gatsby"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import useQuickLinksNavItems from "../../hooks/useQuickLinksNavItems"
import MenuIcon from "./MenuIcon"

const MenuIcons: React.FC<{ styling?: any }> = ({ styling }) => {
  const navItems = useQuickLinksNavItems()

  return (
    <>
      {navItems?.map(({ label, path, quickLinksIcons }, index) => (
        <MenuIcon
          label={label}
          path={path}
          quickLinksIcons={quickLinksIcons}
          index={index}
          styling={styling}
          key={index}
        />
      ))}
    </>
  )
}

export default MenuIcons
