import { useEffect, useState } from "react"

const useOpenDropdown = ref => {
  const [open, setOpen] = useState(false)
  const [hover, setHover] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [open, hover, ref])
  return { open, hover, setOpen, setHover, handleOpen }
}

export default useOpenDropdown
