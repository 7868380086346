import { Link } from "gatsby"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { SearchResultProps } from "./Search.d"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"

const SearchResult: React.FC<SearchResultProps> = ({
  title,
  external_id,
  url,
  description,
  key,
  body,
  onClick,
  ...remainingProps
}) => {
  const [text, setText] = useState({ title, body })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, body })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, body])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [external_id, language])

  return (
    <div css={[tw`mb-4`]}>
      <Link to={url} key={key} {...remainingProps} onClick={onClick}>
        {text?.title}
      </Link>
      {body && <p css={[tw`line-clamp-3`]}>{text?.body}</p>}
    </div>
  )
}

export default SearchResult
