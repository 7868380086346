import { getImage } from "gatsby-plugin-image"
import React from "react"
import tw from "twin.macro"
import useFooterData from "../../hooks/useFooterData"
import useSocialLinks from "../../hooks/useSocialLinks"
import FooterBackground from "./FooterBackground"

import FooterInfo from "./FooterInfo"
import FooterMenu from "./FooterMenu"
import FooterSocials from "./FooterSocials"

const Footer: React.FC<{ mobileMenu: boolean }> = ({ mobileMenu }) => {
  const { address, backgroundImage, email, phone } = useFooterData()
  const backgroundImageSrc = getImage(backgroundImage?.localFile)

  return (
    <footer css={[tw`w-full relative`, mobileMenu && tw`hidden`]}>
      <div
        css={[
          tw`w-full flex flex-col text-grayBlack h-fit mb-0 lg:mb-32`,
        ]}
      >
        <FooterSocials />
        <FooterInfo address={address} email={email} phone={phone} />
        {backgroundImage && (
          <FooterBackground backgroundImageSrc={backgroundImageSrc} />
        )}
      </div>
      <FooterMenu />
    </footer>
  )
}

export default Footer
