import tw from "twin.macro"
import styled from "@emotion/styled"

const headingClasses = tw`font-extrabold font-sans`

export const H1 = styled.h1(() => [
  tw`text-3xl mb-6 md:text-4xl`,
  headingClasses,
])
export const H2 = styled.h2(() => [tw`text-2xl`, headingClasses])
export const H3 = styled.h3(() => [tw`text-xl`, headingClasses])
export const H4 = styled.h4(() => [tw`text-lg`, headingClasses])
