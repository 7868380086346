import React from "react"
import tw from "twin.macro"

const MenuArrow: React.FC<{ type: string; color: string }> = ({
  type = "",
  color = "",
}) => (
  <svg
    css={[
      tw`transition-transform duration-500`,
      type === "up" ? tw`rotate-0` : tw`translate-y-px rotate-180`,
    ]}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 15.8 13 11l-4 4.8"
      stroke={color}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default MenuArrow
