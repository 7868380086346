import React from "react"

const DayWindyCloudy: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      css={[styling]}
      fill="#fff"
    >
      <path
        d="M0.35,21.01c0,0.24,0.09,0.44,0.27,0.6c0.17,0.17,0.37,0.25,0.61,0.25h5.88c0.26,0,0.48,0.09,0.68,0.28
	c0.2,0.19,0.3,0.42,0.3,0.68s-0.1,0.5-0.3,0.69c-0.2,0.19-0.42,0.29-0.68,0.29c-0.26,0-0.48-0.1-0.68-0.3
	c-0.18-0.16-0.38-0.24-0.61-0.24c-0.24,0-0.44,0.08-0.6,0.24c-0.16,0.16-0.24,0.36-0.24,0.6c0,0.24,0.08,0.44,0.24,0.6
	c0.53,0.53,1.16,0.8,1.89,0.8c0.74,0,1.37-0.26,1.88-0.78c0.52-0.52,0.78-1.15,0.78-1.89s-0.26-1.37-0.78-1.89
	c-0.52-0.53-1.15-0.79-1.88-0.79H1.23c-0.24,0-0.44,0.09-0.62,0.26S0.35,20.78,0.35,21.01z M0.35,18c0,0.22,0.09,0.41,0.27,0.57
	c0.17,0.17,0.37,0.25,0.61,0.25H12.2c0.74,0,1.37-0.26,1.89-0.78c0.52-0.52,0.78-1.15,0.78-1.89c0-0.74-0.26-1.36-0.78-1.88
	c-0.52-0.51-1.15-0.77-1.89-0.77c-0.76,0-1.38,0.25-1.88,0.76c-0.16,0.16-0.23,0.37-0.23,0.61c0,0.24,0.08,0.44,0.23,0.6
	c0.15,0.15,0.35,0.23,0.6,0.23c0.24,0,0.44-0.08,0.62-0.23c0.19-0.19,0.41-0.28,0.67-0.28c0.26,0,0.49,0.09,0.68,0.28
	c0.19,0.19,0.29,0.42,0.29,0.68c0,0.27-0.1,0.5-0.29,0.69c-0.19,0.19-0.42,0.29-0.68,0.29H1.23c-0.24,0-0.44,0.09-0.62,0.26
	C0.43,17.57,0.35,17.77,0.35,18z M3,15.65c0,0.08,0.06,0.12,0.17,0.12H4.6c0.07,0,0.13-0.05,0.2-0.14c0.22-0.54,0.57-0.99,1.05-1.35
	c0.47-0.35,1-0.55,1.6-0.6l0.53-0.07c0.12,0,0.19-0.06,0.19-0.17l0.07-0.52c0.11-1.08,0.56-1.98,1.37-2.71
	c0.81-0.73,1.76-1.09,2.85-1.09c1.1,0,2.05,0.36,2.86,1.08s1.27,1.63,1.38,2.71l0.07,0.58c0,0.12,0.06,0.18,0.18,0.18h1.63
	c0.9,0,1.67,0.31,2.3,0.94c0.63,0.63,0.95,1.39,0.95,2.27c0,0.89-0.32,1.66-0.95,2.29c-0.63,0.63-1.4,0.95-2.3,0.95h-6.9
	c-0.13,0-0.19,0.06-0.19,0.18v1.37c0,0.11,0.06,0.17,0.19,0.17h6.9c0.89,0,1.72-0.22,2.48-0.67c0.76-0.44,1.36-1.05,1.8-1.81
	c0.44-0.76,0.66-1.59,0.66-2.48c0-0.74-0.14-1.41-0.42-2.03c0.76-0.99,1.13-2.1,1.13-3.31c0-0.94-0.24-1.81-0.71-2.62
	s-1.11-1.45-1.92-1.92c-0.81-0.47-1.68-0.71-2.62-0.71c-1.54,0-2.83,0.58-3.86,1.73c-0.8-0.41-1.69-0.61-2.67-0.61
	c-1.41,0-2.65,0.44-3.73,1.31s-1.77,1.99-2.06,3.34c-0.85,0.2-1.6,0.61-2.25,1.23c-0.65,0.62-1.11,1.35-1.36,2.19v0.04
	C3.01,15.59,3,15.62,3,15.65z M11.18,4.62c0,0.23,0.09,0.43,0.27,0.6l0.61,0.65c0.16,0.16,0.37,0.24,0.61,0.24
	c0.25,0,0.45-0.08,0.61-0.23c0.16-0.15,0.24-0.35,0.24-0.6c0-0.24-0.07-0.44-0.23-0.6l-0.66-0.65c-0.16-0.17-0.35-0.26-0.59-0.26
	s-0.44,0.08-0.61,0.25C11.26,4.19,11.18,4.39,11.18,4.62z M16.5,9.03c0.72-0.68,1.54-1.02,2.48-1.02c0.97,0,1.8,0.35,2.51,1.05
	c0.7,0.7,1.05,1.54,1.05,2.51c0,0.65-0.17,1.26-0.52,1.83c-0.96-0.96-2.11-1.43-3.46-1.43h-0.34C17.99,10.88,17.41,9.9,16.5,9.03z
	 M18.12,3.79c0,0.23,0.08,0.43,0.25,0.59c0.17,0.16,0.37,0.24,0.6,0.24c0.24,0,0.44-0.08,0.61-0.24c0.17-0.16,0.25-0.35,0.25-0.59
	V1.74c0-0.24-0.08-0.44-0.25-0.61c-0.17-0.17-0.37-0.25-0.61-0.25c-0.24,0-0.44,0.08-0.6,0.25s-0.25,0.37-0.25,0.61V3.79z
	 M23.67,6.06c0,0.24,0.08,0.44,0.23,0.6c0.17,0.17,0.37,0.25,0.61,0.26s0.43-0.08,0.57-0.26l1.46-1.43
	c0.17-0.17,0.25-0.37,0.25-0.61c0-0.23-0.08-0.43-0.25-0.6c-0.17-0.17-0.37-0.25-0.61-0.25S25.49,3.84,25.33,4L23.9,5.47
	C23.75,5.63,23.67,5.82,23.67,6.06z M24.44,17.89c0,0.23,0.08,0.43,0.25,0.6l0.64,0.65c0.2,0.16,0.41,0.24,0.62,0.24
	c0.19,0,0.39-0.08,0.59-0.24c0.17-0.17,0.25-0.37,0.25-0.6c0-0.22-0.08-0.42-0.25-0.61l-0.64-0.65c-0.16-0.16-0.36-0.24-0.58-0.24
	c-0.25,0-0.46,0.08-0.63,0.25C24.52,17.45,24.44,17.65,24.44,17.89z M25.95,11.57c0,0.24,0.08,0.43,0.25,0.59
	c0.15,0.18,0.34,0.26,0.57,0.26h2.02c0.24,0,0.44-0.08,0.61-0.25c0.17-0.17,0.25-0.37,0.25-0.6c0-0.23-0.09-0.43-0.26-0.6
	c-0.17-0.17-0.37-0.26-0.6-0.26h-2.02c-0.24,0-0.43,0.08-0.59,0.25C26.03,11.13,25.95,11.33,25.95,11.57z"
      />
    </svg>
  )
}

export default DayWindyCloudy
