import React from "react"

const NightModerateRain: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      css={[styling]}
      fill="#fff"
    >
      <path
        d="M4.25,16.92c0,0.66,0.12,1.28,0.38,1.88c0.25,0.6,0.59,1.11,1.02,1.55c0.43,0.44,0.94,0.79,1.53,1.06s1.22,0.42,1.88,0.45
	c0.12,0,0.18-0.06,0.18-0.17v-1.34c0-0.11-0.06-0.17-0.18-0.17c-0.85-0.04-1.58-0.39-2.18-1.03s-0.9-1.39-0.9-2.24
	s0.28-1.58,0.84-2.2s1.26-0.96,2.1-1.03l0.53-0.07c0.1,0,0.15-0.05,0.15-0.15l0.08-0.53c0.11-1.09,0.58-2,1.4-2.73
	c0.82-0.73,1.78-1.09,2.88-1.09c1.09,0,2.04,0.36,2.85,1.08c0.82,0.72,1.28,1.62,1.4,2.7l0.07,0.57c0,0.12,0.06,0.19,0.18,0.19h1.62
	c0.89,0,1.65,0.32,2.29,0.96c0.64,0.64,0.96,1.41,0.96,2.31c0,0.85-0.3,1.6-0.89,2.24s-1.32,0.98-2.17,1.03
	c-0.13,0-0.19,0.06-0.19,0.17v1.34c0,0.11,0.06,0.17,0.19,0.17c1.34-0.06,2.47-0.57,3.39-1.52c0.93-0.95,1.39-2.09,1.39-3.42
	c0-0.64-0.14-1.31-0.41-2c0.76-0.99,1.13-2.09,1.13-3.29c0-0.94-0.24-1.82-0.71-2.63s-1.11-1.45-1.92-1.92
	c-0.81-0.47-1.68-0.71-2.62-0.71c-1.53,0-2.82,0.56-3.86,1.67c-0.87-0.43-1.77-0.65-2.7-0.65c-1.42,0-2.68,0.44-3.77,1.32
	s-1.79,2.01-2.1,3.38c-1.13,0.26-2.05,0.84-2.76,1.72S4.25,15.74,4.25,16.92z M9.64,23.67c0,0.17,0.05,0.33,0.15,0.48
	c0.1,0.15,0.26,0.27,0.48,0.34c0.11,0.06,0.24,0.08,0.37,0.07c0.13-0.01,0.27-0.08,0.4-0.2c0.13-0.12,0.23-0.28,0.28-0.48l0.28-1.01
	c0.06-0.25,0.04-0.48-0.08-0.67c-0.12-0.2-0.29-0.32-0.53-0.37c-0.21-0.07-0.42-0.05-0.63,0.07s-0.34,0.28-0.41,0.5l-0.28,1.04
	C9.65,23.53,9.64,23.61,9.64,23.67z M10.95,18.9c0.01,0.19,0.06,0.37,0.17,0.52s0.27,0.25,0.48,0.28c0.18,0.03,0.27,0.05,0.3,0.05
	c0.38,0,0.63-0.22,0.76-0.66l0.28-1c0.06-0.23,0.04-0.45-0.08-0.66c-0.12-0.21-0.29-0.35-0.53-0.42c-0.22-0.06-0.44-0.04-0.64,0.08
	s-0.33,0.29-0.4,0.52l-0.3,1.05C10.97,18.78,10.95,18.86,10.95,18.9z M12.19,26.8c0,0.18,0.05,0.34,0.15,0.5
	c0.1,0.16,0.26,0.27,0.48,0.33c0.14,0.03,0.23,0.05,0.28,0.05c0.09,0,0.21-0.03,0.38-0.1c0.17-0.08,0.3-0.27,0.38-0.55l0.3-1.01
	c0.06-0.25,0.03-0.48-0.08-0.68c-0.12-0.2-0.29-0.33-0.52-0.37c-0.22-0.07-0.43-0.05-0.64,0.07c-0.21,0.12-0.35,0.29-0.42,0.51
	l-0.28,1.04C12.2,26.67,12.19,26.74,12.19,26.8z M13.52,22c0,0.17,0.05,0.33,0.16,0.49c0.11,0.16,0.27,0.27,0.49,0.33
	c0.22,0.07,0.44,0.05,0.63-0.05c0.2-0.1,0.33-0.29,0.41-0.56l0.28-1.01c0.07-0.25,0.05-0.47-0.07-0.67
	c-0.12-0.19-0.29-0.31-0.53-0.36c-0.22-0.08-0.43-0.05-0.64,0.06s-0.34,0.29-0.41,0.51l-0.28,1.04C13.54,21.88,13.52,21.95,13.52,22
	z M16.37,23.75c-0.01,0.16,0.03,0.31,0.14,0.46s0.26,0.26,0.46,0.33l0.25,0.03c0.11,0.01,0.24-0.02,0.38-0.07
	c0.21-0.08,0.35-0.26,0.42-0.54l0.28-1.05c0.07-0.23,0.05-0.45-0.07-0.64c-0.12-0.2-0.29-0.33-0.51-0.4
	c-0.25-0.06-0.47-0.03-0.67,0.08s-0.32,0.3-0.36,0.53l-0.29,1C16.38,23.65,16.37,23.74,16.37,23.75z M17.72,18.95
	c0,0.17,0.05,0.34,0.16,0.5c0.11,0.16,0.27,0.26,0.48,0.3l0.25,0.03c0.43,0,0.7-0.21,0.81-0.62l0.28-1.03
	c0.06-0.25,0.03-0.48-0.08-0.68s-0.3-0.32-0.53-0.37c-0.23-0.07-0.45-0.05-0.64,0.07c-0.2,0.12-0.33,0.29-0.39,0.53l-0.3,1.02
	C17.74,18.82,17.72,18.9,17.72,18.95z M18.06,9.05c0.67-0.64,1.48-0.97,2.45-0.97c0.98,0,1.81,0.35,2.49,1.05
	c0.69,0.7,1.03,1.53,1.03,2.51c0,0.64-0.16,1.23-0.48,1.77c-0.96-0.96-2.12-1.44-3.49-1.44h-0.32C19.5,10.87,18.94,9.9,18.06,9.05z"
      />
    </svg>
  )
}

export default NightModerateRain
