import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
import { Link } from 'gatsby';
import tw from 'twin.macro';
const {getStringValuesArray, mapValuesToObject} = require("../../helpers")

interface Props {
  path: string;
  label: string;
  handleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  handleBlur: any;
  open: boolean;
}

const MobileLink: React.FC<Props> = ({path, label, handleMobileMenu, handleBlur, open}) => {

  const [text, setText] = useState({ label })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ label })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([label])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <Link
      to={path}
      aria-expanded={open}
      aria-hidden={open}
      tabIndex={!open && -1}
      onBlur={handleBlur}
      onClick={() => handleMobileMenu(false)}
      css={[
        tw`flex items-center w-full text-lg tracking-tighter border-t border-lightGray h-12 hover:underline focus:underline`,
      ]}
      style={{ outlineColor: "#AED1D3", outlineOffset: "0" }}
    >
      {text?.label}
    </Link>
  )
}

export default MobileLink