import { Link } from "gatsby"
import React from "react"
import useButtonStates from "../../hooks/useButtonStates"
import tw from "twin.macro"

const Facebook: React.FC<{ link: string }> = ({ link }) => {
  const {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    hover,
    focus,
  } = useButtonStates()

  return (
    <Link
      to={link}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      css={[tw`transition-colors duration-500`]}
      style={{ outline: "none" }}
      className="group"
      aria-label="facebook link"
    >
      <svg
        width="80"
        height="80"
        viewBox="-5 10 80 50"
        xmlns="http://www.w3.org/2000/svg"
        css={[
          tw`border-2 border-transparent rounded-full group-focus-visible:border-blue-150`,
        ]}
      >
        <g fill="none">
          <circle
            style={{
              transition: hover || focus ? "all 500ms ease" : "all 300ms ease",
            }}
            fill={hover || focus ? "#E1E6EC" : "#385885"}
            cx="35.5"
            cy="35.5"
            r="35.5"
          />

          <path
            style={{
              transition: hover || focus ? "all 500ms ease" : "all 300ms ease",
            }}
            d="M39.1 56V38.5h7.05l1.176-7.5H39.1v-3.75c0-2.5 1.178-3.75 3.525-3.75h3.526V16h-5.876c-5.875 0-8.225 3.75-8.225 10v5H25v7.5h7.05V56h7.05z"
            fill={hover || focus ? "#385885" : "#FFF"}
          />
        </g>
      </svg>
    </Link>
  )
}

export default Facebook
