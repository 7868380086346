import React from "react"
import { LinkProps } from "./Link.d"
import { Link as GatsbyLink } from "gatsby"
import { css } from "@emotion/react"
import tw from "twin.macro"

const Link: React.FC<LinkProps> = ({
  to,
  onClick,
  onFocus,
  activeClassName,
  tabIndex,
  disabled,
  children,
  ...remainingProps
}) => {
  /* Checks to see if "to" is an internal link (first character is a "/") */
  const isInternal = /^\/(?!\/)/.test(to)
  /* Checks to see if "to" is a pdf link (starts wiht "/static") */
  const isStaticFile = /^\/static/.test(to)
  const baseStyles = tw`inline-block relative text-blue-250 font-semibold`

  if(!isInternal || isStaticFile) {
    return (
      <a 
        href={to}
        css={[
          baseStyles,
          tw`hover:text-blue-300 hover:underline`,
          tw`focus:text-blue-300 focus:underline`,
          disabled && tw`opacity-30 pointer-events-none cursor-not-allowed`,
        ]}
        target="_blank"
        {...remainingProps}
      >
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink
      to={to === "/home/" ? "/" : to}
      activeClassName={activeClassName}
      onClick={onClick}
      onFocus={onFocus}
      tabIndex={tabIndex}
      css={[
        baseStyles,
        tw`hover:text-blue-300 hover:underline`,
        tw`focus:text-blue-300 focus:underline`,
        disabled && tw`opacity-30 pointer-events-none cursor-not-allowed`,
      ]}
      {...remainingProps}
    >
      {children}
    </GatsbyLink>
  )
}

export default Link
