import React from "react"

const DayWindyRain: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      css={[styling]}
      fill="#fff"
    >
      <path
        d="M1.48,16.91c0,1.12,0.33,2.13,1,3.01c0.67,0.88,1.53,1.47,2.58,1.77c0.09,0.01,0.17-0.01,0.24-0.08l1.17-1.41
	c-0.89,0-1.66-0.32-2.3-0.97s-0.96-1.42-0.96-2.33c0-0.83,0.28-1.55,0.85-2.17c0.57-0.61,1.27-0.97,2.11-1.07l0.49-0.03
	c0.13,0,0.2-0.06,0.2-0.19l0.07-0.54c0.11-1.08,0.57-1.99,1.38-2.72s1.77-1.1,2.86-1.1c1.1,0,2.06,0.37,2.88,1.1
	s1.28,1.64,1.39,2.72l0.07,0.59c0.04,0.11,0.12,0.17,0.22,0.17h1.64c0.88,0,1.64,0.32,2.28,0.95c0.64,0.63,0.96,1.4,0.96,2.28
	c0,0.84-0.28,1.58-0.85,2.21c-0.57,0.63-1.25,0.98-2.07,1.05c-0.49,0.06-0.79,0.15-0.9,0.28l-2.19,2.82
	c-0.14,0.19-0.19,0.4-0.16,0.63c0.03,0.23,0.13,0.42,0.31,0.56c0.15,0.17,0.35,0.24,0.6,0.21c0.25-0.03,0.45-0.15,0.6-0.36
	l1.92-2.46c0.82-0.08,1.57-0.35,2.25-0.82c0.68-0.46,1.21-1.06,1.61-1.79c0.39-0.73,0.59-1.51,0.59-2.34c0-0.72-0.14-1.39-0.42-2.01
	c0.79-0.96,1.18-2.07,1.18-3.33c0-0.71-0.14-1.38-0.42-2.03c-0.28-0.65-0.66-1.21-1.13-1.68c-0.47-0.47-1.03-0.84-1.68-1.12
	c-0.65-0.28-1.33-0.42-2.04-0.42c-1.5,0-2.79,0.58-3.88,1.74c-0.79-0.44-1.7-0.66-2.72-0.66c-1.41,0-2.66,0.44-3.75,1.32
	s-1.78,2-2.07,3.37c-1.13,0.26-2.06,0.83-2.79,1.73S1.48,15.76,1.48,16.91z M5.38,25.15c0.07,0.22,0.23,0.38,0.46,0.48
	c0.22,0.1,0.44,0.1,0.67,0.01s0.38-0.25,0.46-0.47c0.09-0.21,0.1-0.41,0-0.62c-0.09-0.21-0.24-0.36-0.45-0.46
	c-0.22-0.1-0.44-0.11-0.66-0.02c-0.22,0.09-0.37,0.24-0.45,0.46C5.32,24.78,5.31,24.99,5.38,25.15z M7.19,22.41v0.11
	c0.02,0.22,0.12,0.4,0.3,0.55c0.18,0.15,0.38,0.22,0.63,0.2c0.24-0.02,0.43-0.12,0.57-0.29l2.18-2.82c0.14-0.18,0.21-0.39,0.19-0.63
	s-0.12-0.43-0.29-0.57c-0.18-0.15-0.39-0.21-0.63-0.19c-0.24,0.02-0.44,0.13-0.6,0.31l-2.15,2.8C7.25,22.04,7.19,22.22,7.19,22.41z
	 M7.7,27.27c0,0.06,0.02,0.16,0.06,0.3c0.09,0.22,0.24,0.37,0.46,0.46c0.11,0.04,0.23,0.07,0.36,0.07c0.09,0,0.2-0.02,0.31-0.05
	c0.21-0.08,0.36-0.23,0.44-0.44c0.1-0.22,0.11-0.44,0.02-0.67c-0.08-0.23-0.23-0.38-0.45-0.46c-0.22-0.1-0.44-0.1-0.67-0.01
	s-0.38,0.24-0.45,0.45C7.73,27.03,7.7,27.15,7.7,27.27z M9.37,24.9V25c0.02,0.23,0.13,0.42,0.33,0.58c0.15,0.16,0.35,0.23,0.6,0.2
	c0.25-0.03,0.45-0.14,0.6-0.34l4.14-5.23c0.14-0.2,0.19-0.41,0.17-0.64s-0.13-0.42-0.31-0.59c-0.18-0.14-0.39-0.2-0.62-0.17
	c-0.23,0.03-0.42,0.13-0.56,0.31l-4.16,5.26C9.43,24.53,9.37,24.71,9.37,24.9z M9.95,4.61c0,0.24,0.09,0.44,0.27,0.59l0.61,0.66
	c0.16,0.16,0.34,0.24,0.54,0.26c0.21,0.03,0.41-0.04,0.61-0.22c0.2-0.18,0.3-0.39,0.3-0.64c0-0.22-0.08-0.43-0.24-0.6L11.4,4.01
	c-0.15-0.16-0.34-0.24-0.58-0.24c-0.24,0-0.45,0.08-0.62,0.24C10.04,4.18,9.95,4.38,9.95,4.61z M12.9,26.04
	c0,0.12,0.02,0.22,0.07,0.31c0.09,0.22,0.24,0.37,0.45,0.46c0.11,0.05,0.22,0.08,0.35,0.08c0.06,0,0.16-0.02,0.3-0.06
	c0.22-0.09,0.38-0.24,0.46-0.46c0.1-0.22,0.11-0.44,0.02-0.66c-0.08-0.22-0.23-0.37-0.45-0.45c-0.22-0.1-0.44-0.11-0.67-0.02
	c-0.23,0.09-0.38,0.24-0.46,0.45C12.92,25.8,12.9,25.92,12.9,26.04z M15.3,9.03c0.71-0.64,1.53-0.97,2.48-0.97
	c0.99,0,1.83,0.34,2.53,1.03s1.05,1.51,1.05,2.49c0,0.66-0.17,1.28-0.52,1.86c-0.97-0.97-2.13-1.45-3.48-1.45h-0.34
	C16.75,10.83,16.18,9.85,15.3,9.03z M16.93,3.77c0,0.23,0.08,0.43,0.25,0.59c0.17,0.16,0.37,0.24,0.6,0.24
	c0.24,0,0.45-0.08,0.62-0.24C18.57,4.2,18.65,4,18.65,3.77V1.7c0-0.23-0.09-0.43-0.26-0.6c-0.17-0.17-0.38-0.25-0.61-0.25
	c-0.23,0-0.43,0.08-0.6,0.25s-0.25,0.37-0.25,0.6V3.77z M22.5,6.05c0,0.24,0.07,0.44,0.22,0.6c0.18,0.17,0.38,0.25,0.61,0.26
	c0.23,0,0.42-0.08,0.57-0.26L25.4,5.2c0.16-0.16,0.24-0.37,0.24-0.61c0-0.24-0.09-0.44-0.26-0.61c-0.17-0.17-0.37-0.26-0.6-0.26
	c-0.22,0-0.42,0.08-0.6,0.25l-1.45,1.47C22.58,5.61,22.5,5.81,22.5,6.05z M23.28,17.95c0,0.24,0.08,0.44,0.24,0.6l0.66,0.63
	c0.16,0.16,0.34,0.25,0.54,0.27h0.06c0.18,0,0.38-0.09,0.62-0.27c0.16-0.16,0.24-0.36,0.24-0.58c0-0.24-0.08-0.45-0.24-0.63
	l-0.66-0.62c-0.16-0.17-0.36-0.25-0.59-0.25c-0.24,0-0.45,0.08-0.62,0.25C23.37,17.51,23.28,17.72,23.28,17.95z M24.78,11.58
	c0,0.24,0.08,0.44,0.24,0.6c0.16,0.18,0.36,0.27,0.6,0.27h2.04c0.24,0,0.44-0.09,0.61-0.26c0.17-0.17,0.25-0.38,0.25-0.61
	c0-0.23-0.08-0.43-0.25-0.59c-0.17-0.16-0.37-0.24-0.61-0.24h-2.04c-0.24,0-0.44,0.08-0.6,0.24C24.86,11.15,24.78,11.35,24.78,11.58
	z"
      />
    </svg>
  )
}

export default DayWindyRain
