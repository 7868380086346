import { Link } from "gatsby"
import React from "react"
import useButtonStates from "../../hooks/useButtonStates"
import tw from "twin.macro"

const Twitter: React.FC<{ link: string }> = ({ link }) => {
  const {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    handleVisited,
    hover,
    visited,
    focus,
  } = useButtonStates()

  return (
    <Link
      to={link}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleVisited}
      css={[tw`transition-colors duration-500`]}
      style={{ outline: "none" }}
      className="group"
      aria-label="twitter link"
    >
      <svg
        width="80"
        height="80"
        viewBox="-5 10 80 50"
        xmlns="http://www.w3.org/2000/svg"
        css={[
          tw`border-2 border-transparent rounded-full group-focus-visible:border-blue-150`,
        ]}
      >
        <g fill="none" fillRule="evenodd">
          <circle
            style={{
              transition: hover || focus ? "all 500ms ease" : "all 300ms ease",
            }}
            fill={hover || focus ? "#E1E6EC" : "#385885"}
            cx="35.5"
            cy="35.5"
            r="35.5"
          />
          <path
            style={{
              transition: hover || focus ? "all 500ms ease" : "all 300ms ease",
            }}
            d="M57 23.787a17.236 17.236 0 0 1-4.832 1.271c1.737-.999 3.071-2.582 3.7-4.467a17.272 17.272 0 0 1-5.341 1.96C48.99 20.98 46.805 20 44.384 20c-4.645 0-8.41 3.616-8.41 8.078 0 .632.074 1.25.217 1.84-6.99-.336-13.188-3.552-17.338-8.44a7.812 7.812 0 0 0-1.138 4.062c0 2.803 1.485 5.275 3.74 6.725a8.651 8.651 0 0 1-3.808-1.01l-.001.1c0 3.915 2.898 7.18 6.747 7.923a8.795 8.795 0 0 1-3.797.139c1.069 3.207 4.176 5.543 7.856 5.61-2.877 2.166-6.504 3.46-10.445 3.46-.68 0-1.35-.039-2.007-.114A24.546 24.546 0 0 0 28.894 52c15.471 0 23.933-12.309 23.933-22.985 0-.35-.01-.699-.025-1.046A16.706 16.706 0 0 0 57 23.787z"
            fill={hover || focus ? "#385885" : "#FFF"}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </Link>
  )
}

export default Twitter
