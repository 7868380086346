import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import tw from "twin.macro"
import useBlackOutModeData from "../../hooks/useBlackoutModeData"
import Warning from "../Icons/Warning"

const BlackoutMode: React.FC = ({ children }) => {
  const blackoutModeData = useBlackOutModeData()

  const { blackoutHeadline, blackoutDescription, blackoutEnableBlackoutMode } = blackoutModeData

  if (!blackoutEnableBlackoutMode) {
    return children
  }

  return (
    <main
      css={[
        tw`bg-grayBlack fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center p-8`,
      ]}
    >
      <div css={[tw`bg-white max-w-3xl shadow-2xl`]}>
        <header
          css={[
            tw`bg-red text-white p-12 text-lg flex items-center justify-center`,
          ]}
        >
          <Warning css={[tw`w-8 mr-3 inline-block`]} />
          Site disabled
        </header>
        <section css={[tw`text-black text-center p-14`]}>
          <h1 css={[tw`text-3xl font-extrabold`]}>{blackoutHeadline}</h1>
          <p css={[tw`text-2xl`]}>{blackoutDescription}</p>
        </section>
      </div>
    </main>
  )
}

export default BlackoutMode
