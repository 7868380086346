import React from "react"
import { IconProps } from "./Icon.d"

const Close: React.FC<IconProps> = ({
  color,
  iconStyle,
  ...remainingProps
}) => {
  return (
    <svg
      css={[iconStyle]}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...remainingProps}
    >
      <g fill={color} fillRule="evenodd">
        <path d="m2.489.336 20.849 20.85c.185.185.095.577-.202.874l-1.076 1.076c-.297.297-.689.387-.875.202L.336 2.488c-.185-.186-.095-.577.202-.874L1.615.538C1.91.24 2.303.15 2.489.336z" />
        <path d="M.336 21.185 21.186.336c.185-.185.577-.095.874.202l1.076 1.076c.297.297.387.689.202.875L2.488 23.338c-.186.185-.577.095-.874-.203L.538 22.06c-.297-.296-.387-.688-.202-.874z" />
      </g>
    </svg>
  )
}

export default Close
