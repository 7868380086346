import React from "react"

const Warning = ({ ...remainingProps }) => {
  return (
    <svg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...remainingProps}
    >
      <g fill="#FFF" fillRule="evenodd">
        <path d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm-.274 3.836c-8.776 0-15.89 7.114-15.89 15.89s7.114 15.89 15.89 15.89 15.89-7.114 15.89-15.89-7.114-15.89-15.89-15.89zM20 27.946a1.918 1.918 0 1 1 0 3.835 1.918 1.918 0 0 1 0-3.836zm0-19.727c1.06 0 1.918.89 1.918 1.986v11.918c0 1.097-.859 1.987-1.918 1.987-1.06 0-1.918-.89-1.918-1.987V10.205c0-1.097.859-1.986 1.918-1.986z" />
      </g>
    </svg>
  )
}

export default Warning
