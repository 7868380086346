import React, { useState, useContext, useEffect } from "react"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { Link } from "../Link"
import tw from "twin.macro"

export type FooterPrimaryNavItemProps = {
  label: string
  url
}

const FooterPrimaryNavItem: React.FC<FooterPrimaryNavItemProps> = ({
  label,
  url,
}) => {
  const [text, setText] = useState({ label })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ label })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([label])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <li
      css={[
        tw`inline-block text-blue-250 hover:text-blue-600 focus:text-blue-600 text-xs sm:text-sm lg:text-xl xl:text-2xl font-semibold tracking-tight pr-3 sm:pr-5 lg:first-of-type:pl-12 xl:pr-4 2xl:first-of-type:pl-24`,
      ]}
    >
      <Link
        css={[tw`hover:underline`]}
        to={url}
        aria-label={label}
        style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
      >
        {text?.label}
      </Link>
    </li>
  )
}

export default FooterPrimaryNavItem
