import { Link } from "gatsby"
import React from "react"
import useButtonStates from "../../hooks/useButtonStates"
import tw from "twin.macro"

const Instagram: React.FC<{ link: string }> = ({ link }) => {
  const {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    handleVisited,
    hover,
    visited,
    focus,
  } = useButtonStates()

  return (
    <Link
      to={link}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleVisited}
      css={[tw`transition-colors duration-500`]}
      style={{ outline: "none" }}
      className="group"
      aria-label="instagram link"
    >
      <svg
        width="80"
        height="80"
        viewBox="-5 10 80 50"
        xmlns="http://www.w3.org/2000/svg"
        css={[
          tw`border-2 border-transparent rounded-full group-focus-visible:border-blue-150`,
        ]}
      >
        <g fill="none">
          <circle
            style={{
              transition: hover || focus ? "all 500ms ease" : "all 300ms ease",
            }}
            fill={hover || focus ? "#E1E6EC" : "#385885"}
            cx="35.5"
            cy="35.5"
            r="35.5"
          />
          <g
            fill={hover || focus ? "#385885" : "#FFF"}
            style={{
              transition: hover || focus ? "all 500ms ease" : "all 300ms ease",
            }}
          >
            <path d="M36 16c-5.432 0-6.113.023-8.246.12-2.129.097-3.583.436-4.855.93a9.803 9.803 0 0 0-3.542 2.307 9.804 9.804 0 0 0-2.307 3.542c-.495 1.272-.833 2.726-.93 4.855C16.023 29.887 16 30.568 16 36s.023 6.113.12 8.246c.097 2.129.435 3.583.93 4.855a9.803 9.803 0 0 0 2.307 3.542 9.804 9.804 0 0 0 3.542 2.307c1.272.494 2.726.833 4.855.93 2.133.097 2.814.12 8.246.12s6.113-.023 8.246-.12c2.129-.097 3.583-.436 4.855-.93a9.804 9.804 0 0 0 3.542-2.307 9.802 9.802 0 0 0 2.307-3.542c.494-1.272.833-2.726.93-4.855.097-2.133.12-2.814.12-8.246s-.023-6.113-.12-8.246c-.097-2.129-.436-3.583-.93-4.855a9.804 9.804 0 0 0-2.307-3.542 9.802 9.802 0 0 0-3.542-2.307c-1.272-.494-2.726-.833-4.855-.93C42.113 16.023 41.432 16 36 16m0 3.604c5.34 0 5.973.02 8.082.116 1.95.09 3.009.415 3.713.689.934.363 1.6.796 2.3 1.496.7.7 1.133 1.366 1.496 2.3.274.704.6 1.763.689 3.713.096 2.11.116 2.742.116 8.082 0 5.34-.02 5.973-.116 8.082-.09 1.95-.415 3.009-.689 3.713-.363.934-.796 1.6-1.496 2.3-.7.7-1.366 1.133-2.3 1.496-.704.274-1.763.6-3.713.689-2.109.096-2.741.116-8.082.116-5.34 0-5.973-.02-8.082-.116-1.95-.09-3.009-.415-3.714-.689-.933-.363-1.6-.796-2.3-1.496-.699-.7-1.132-1.366-1.495-2.3-.274-.704-.6-1.763-.689-3.713-.096-2.11-.116-2.742-.116-8.082 0-5.34.02-5.973.116-8.082.09-1.95.415-3.009.689-3.713.363-.934.796-1.6 1.496-2.3.7-.7 1.366-1.133 2.3-1.496.704-.274 1.763-.6 3.713-.689 2.11-.096 2.742-.116 8.082-.116" />
            <path d="M36 42.491a6.491 6.491 0 1 1 0-12.982 6.491 6.491 0 0 1 0 12.982M36 26c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10m13 .5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
          </g>
        </g>
      </svg>
    </Link>
  )
}

export default Instagram
