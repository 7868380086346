import React from "react"
import tw from "twin.macro"

const HamburgerMenu: React.FC<{
  mobileMenu: boolean
  setMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  handleMobileMenu: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}> = ({ mobileMenu, setMobileMenu }) => {
  const handleClick = () => {
    setMobileMenu(!mobileMenu)
  }

  const handleFocus = () => {
    if (!mobileMenu) {
      handleClick()
    }
  }

  return (
    <button
      type="button"
      css={[
        tw`flex flex-col justify-center xl:hidden px-3 hover:cursor-pointer relative`,
      ]}
      aria-label={`${open ? "close" : "open"} mobile menu`}
      aria-expanded={mobileMenu ? true : false}
      aria-controls="mobileMenu"
      style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
      onMouseDown={handleClick}
      onFocus={handleFocus}
    >
      <div
        css={[
          tw`w-7.5 h-0.75 bg-white mb-1.75 rounded-xs transition-all duration-300 relative z-1`,
          mobileMenu && tw`-rotate-45 translate-y-2 bg-black`,
        ]}
      />
      <div
        css={[
          tw`w-7.5 h-0.75 bg-white mb-1.75 rounded-xs transition-opacity duration-300 relative z-1`,
          mobileMenu && tw`opacity-0`,
        ]}
      />
      <div
        css={[
          tw`w-7.5 h-0.75 bg-white rounded-xs transition-all duration-300 relative z-1`,
          mobileMenu && tw`rotate-225 -translate-y-3 bg-black`,
        ]}
      />

      <div
        css={[
          tw`h-full w-full left-1/2 top-0 -translate-x-1/2 absolute overflow-hidden`,
        ]}
      >
        <div
          css={[
            tw`h-full w-full absolute left-0 top-full duration-300 rounded-t-md bg-sand`,
            mobileMenu && tw`top-0`,
          ]}
        />
      </div>
    </button>
  )
}

export default HamburgerMenu
