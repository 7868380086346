import React from "react"
import useButtonStates from "../../hooks/useButtonStates"

const DineShop: React.FC<{ iconStyle?: any; parentHover?: boolean }> = ({
  iconStyle,
  parentHover,
}) => {
  const {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    handleVisited,
    hover,
    visited,
    focus,
  } = useButtonStates()

  return (
    <svg
      css={[iconStyle]}
      viewBox="50.41 11.26 44.29 42.78"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleVisited}
      style={{
        outlineWidth: focus && "1px",
        outlineColor: focus && "#9ED8D5",
        outlineOffset: focus && "1px",
        outlineStyle: focus && "dotted",
        animation: parentHover && "bounceUp 0.6s",
        animationIterationCount: parentHover && 1,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(56 14)">
          <path
            d="M3.061 10 0 40h32.143l-3.061-30H3.062z"
            stroke={
              hover || parentHover ? "#9ED8D5" : visited ? "#FFF" : "#FFF"
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="1"
          />
          <path
            d="M23.143 14.286V7.563C23.143 3.386 20.12 0 16.393 0s-6.75 3.386-6.75 7.563v6.723"
            stroke={
              hover || parentHover ? "#9ED8D5" : visited ? "#FFF" : "#FFF"
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="1"
          />
          <ellipse
            fill={hover || parentHover ? "#9ED8D5" : visited ? "#FFF" : "#FFF"}
            fillRule="nonzero"
            cx="22.821"
            cy="14.643"
            rx="1.607"
            ry="1.786"
          />
          <ellipse
            fill={hover || parentHover ? "#9ED8D5" : visited ? "#FFF" : "#FFF"}
            fillRule="nonzero"
            cx="9.321"
            cy="14.643"
            rx="1.607"
            ry="1.786"
          />
        </g>
        <g
          stroke={hover || parentHover ? "#9ED8D5" : visited ? "#FFF" : "#FFF"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M76.616 39.37a3.136 3.136 0 0 0 0-4.474l-5.34 5.34-1.78 1.78-2.742 2.741a1.163 1.163 0 0 0 0 1.684h0c.482.481 1.347.481 1.78-.096l1.973-2.453c.48-.626 1.346-.818 2.116-.53h0l3.993-3.992zM69.497 38.696l-2.598-2.597" />
          <path d="M70.555 40.957c-.193-.048-.337-.048-.53-.096-.625-.048-1.346-.385-2.068-1.106L65.6 37.398M73.826 42.16l3.367 2.742c.53.385.577 1.154.096 1.587-.48.481-1.202.385-1.587-.096l-2.742-3.415M68.198 34.8l2.405 2.357c.721.722 1.106 1.443 1.106 2.069 0 .192.048.336.096.529" />
        </g>
      </g>
    </svg>
  )
}

export default DineShop
