import { graphql, useStaticQuery } from "gatsby"

const useBlackOutModeData = () => {
  const blackOutModeData = useStaticQuery(graphql`
    {
      wp {
        siteSettings {
          blackoutMode {
            blackoutDescription
            blackoutEnableBlackoutMode
            blackoutHeadline
          }
        }
      }
    }
  `)

  return blackOutModeData.wp.siteSettings.blackoutMode
}

export default useBlackOutModeData
