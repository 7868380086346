import { SearchClientResponse } from "./SearchClient.d"

class SearchClient {
  public static getSearchResults = async (
    query: string
  ): Promise<SearchClientResponse> => {
    const body = {
      q: query,
      engine_key: process.env.GATSBY_SWIFTYPE_ENGINE_KEY,
    }

    try {
      const res = await fetch(
        "https://search-api.swiftype.com/api/v1/public/engines/search.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
      return await res.json()
    } catch (err) {
      console.log(`error in search: ${err}`)
    }
  }

  public static getPopularSearchQueries =
    async (): Promise<SearchClientResponse> => {
      try {
        const res = await fetch(
          `${process.env.GATSBY_BAA_API_URL}/search/popularQueries`,
          {
            method: "GET",
            headers: {
              "x-api-key": process.env.GATSBY_BAA_API_KEY,
            },
          }
        )
        return await res.json()
      } catch (err) {
        console.log(err)
      }
    }
}

export default SearchClient
