import React from "react"

const Notifications: React.FC<{ styling: any; number: number }> = ({
  styling,
  number,
}) => {
  return (
    <div css={[styling]}>
      <span>{number}</span>
    </div>
  )
}

export default Notifications
