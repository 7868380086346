import React from "react"

const DayWindy: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      css={[styling]}
      fill="#fff"
    >
      <path
        d="M1.48,21.1c0,0.24,0.09,0.44,0.27,0.6c0.17,0.17,0.37,0.25,0.61,0.25h5.88c0.26,0,0.48,0.09,0.68,0.28
	c0.2,0.19,0.3,0.42,0.3,0.68s-0.1,0.5-0.3,0.69c-0.2,0.19-0.42,0.29-0.68,0.29c-0.26,0-0.48-0.1-0.68-0.3
	c-0.18-0.16-0.38-0.24-0.61-0.24c-0.24,0-0.44,0.08-0.6,0.24c-0.16,0.16-0.24,0.36-0.24,0.6c0,0.24,0.08,0.44,0.24,0.6
	c0.53,0.53,1.16,0.8,1.89,0.8c0.74,0,1.37-0.26,1.88-0.78s0.78-1.15,0.78-1.89s-0.26-1.37-0.78-1.89c-0.52-0.52-1.15-0.78-1.88-0.78
	H2.36c-0.24,0-0.44,0.08-0.62,0.25C1.57,20.67,1.48,20.87,1.48,21.1z M1.48,18.09c0,0.23,0.09,0.42,0.27,0.58
	c0.16,0.16,0.37,0.24,0.61,0.24h10.97c0.74,0,1.37-0.26,1.89-0.78c0.52-0.52,0.78-1.15,0.78-1.89c0-0.74-0.26-1.36-0.78-1.88
	c-0.52-0.51-1.15-0.77-1.89-0.77c-0.76,0-1.38,0.25-1.88,0.76c-0.16,0.16-0.23,0.37-0.23,0.61c0,0.24,0.08,0.44,0.23,0.6
	c0.15,0.15,0.35,0.23,0.6,0.23c0.24,0,0.44-0.08,0.62-0.23c0.19-0.19,0.41-0.28,0.68-0.28s0.49,0.09,0.68,0.28s0.29,0.42,0.29,0.68
	c0,0.27-0.1,0.5-0.29,0.69c-0.19,0.19-0.42,0.29-0.68,0.29H2.36c-0.24,0-0.44,0.09-0.62,0.26C1.57,17.66,1.48,17.86,1.48,18.09z
	 M7.27,11.55c0-0.24,0.09-0.44,0.26-0.62c0.18-0.16,0.38-0.24,0.6-0.24h2.03c0.23,0,0.42,0.08,0.58,0.25
	c0.16,0.17,0.23,0.37,0.23,0.61c0,0.24-0.08,0.44-0.23,0.6c-0.16,0.17-0.35,0.25-0.58,0.25H8.13c-0.24,0-0.44-0.08-0.61-0.25
	C7.35,11.98,7.27,11.78,7.27,11.55z M10.12,4.63c0-0.23,0.08-0.43,0.23-0.61c0.19-0.16,0.41-0.24,0.64-0.24
	c0.22,0,0.42,0.08,0.59,0.24l1.43,1.47c0.16,0.15,0.24,0.35,0.24,0.59c0,0.24-0.08,0.44-0.24,0.6s-0.36,0.24-0.59,0.24
	c-0.24,0-0.44-0.08-0.59-0.24l-1.47-1.43C10.2,5.08,10.12,4.88,10.12,4.63z M12.68,11.43v-0.07c0.02-0.91,0.27-1.75,0.74-2.53
	c0.48-0.77,1.11-1.38,1.9-1.83c0.79-0.45,1.65-0.67,2.57-0.67c0.7,0,1.37,0.14,2.02,0.42c0.64,0.28,1.2,0.65,1.66,1.12
	c0.47,0.47,0.84,1.02,1.11,1.66s0.41,1.32,0.41,2.02c0,0.94-0.23,1.8-0.69,2.6s-1.09,1.43-1.88,1.89c-0.79,0.47-1.66,0.7-2.6,0.71
	h-0.21c-0.07,0-0.13-0.02-0.17-0.07c-0.05-0.05-0.07-0.11-0.07-0.18v-1.22c0-0.13,0.07-0.2,0.22-0.2h0.24
	c0.96-0.01,1.79-0.35,2.47-1.05c0.68-0.69,1.03-1.52,1.03-2.49c0-0.96-0.35-1.78-1.04-2.47c-0.69-0.68-1.52-1.02-2.5-1.02
	c-0.94,0-1.76,0.32-2.44,0.98c-0.68,0.65-1.04,1.44-1.08,2.37c0,0.06-0.03,0.11-0.08,0.17s-0.14,0.09-0.26,0.09H12.9
	C12.75,11.67,12.68,11.59,12.68,11.43z M17.03,21.31v-1.99c0-0.24,0.08-0.44,0.25-0.6s0.37-0.24,0.6-0.24
	c0.25,0,0.45,0.08,0.61,0.24c0.16,0.16,0.24,0.36,0.24,0.6v1.99c0,0.24-0.08,0.45-0.25,0.62c-0.17,0.17-0.37,0.25-0.6,0.25
	c-0.24,0-0.44-0.08-0.6-0.25C17.12,21.76,17.03,21.55,17.03,21.31z M17.03,3.83V1.78c0-0.23,0.08-0.43,0.25-0.6s0.37-0.25,0.6-0.25
	c0.24,0,0.44,0.08,0.6,0.25s0.25,0.37,0.25,0.6v2.04c0,0.23-0.08,0.42-0.25,0.58c-0.17,0.15-0.37,0.23-0.6,0.23
	c-0.24,0-0.44-0.08-0.6-0.23C17.12,4.25,17.03,4.06,17.03,3.83z M22.56,17.01c0-0.23,0.08-0.42,0.23-0.56
	c0.15-0.16,0.34-0.23,0.57-0.23c0.24,0,0.44,0.08,0.6,0.23l1.45,1.42c0.16,0.17,0.24,0.38,0.24,0.61c0,0.23-0.08,0.43-0.24,0.59
	c-0.4,0.31-0.8,0.31-1.2,0l-1.42-1.43C22.63,17.48,22.56,17.26,22.56,17.01z M22.56,6.08c0-0.25,0.08-0.45,0.23-0.59l1.42-1.47
	c0.18-0.16,0.37-0.24,0.59-0.24c0.23,0,0.43,0.08,0.6,0.25c0.17,0.17,0.25,0.37,0.25,0.6c0,0.25-0.08,0.46-0.24,0.62l-1.45,1.43
	c-0.18,0.16-0.38,0.24-0.6,0.24c-0.23,0-0.41-0.08-0.57-0.24C22.63,6.52,22.56,6.32,22.56,6.08z M24.82,11.55
	c0-0.24,0.08-0.44,0.24-0.62c0.16-0.16,0.35-0.24,0.57-0.24h2.02c0.23,0,0.43,0.09,0.61,0.26s0.26,0.37,0.26,0.6
	c0,0.23-0.09,0.43-0.26,0.6c-0.18,0.17-0.38,0.25-0.61,0.25h-2.02c-0.23,0-0.42-0.08-0.58-0.25C24.89,11.99,24.82,11.79,24.82,11.55
	z"
      />
    </svg>
  )
}

export default DayWindy
