import React, { useState, useContext, useEffect, useRef } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { Link } from "../Link"

export type HeaderNavItemProps = {
  url: string
  label: string
  handleBlur?: (e: any) => void
  tabVar: boolean
  handleClick?: () => void
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({
  url,
  label,
  handleBlur,
  tabVar,
  handleClick,
}) => {
  const [text, setText] = useState({ label })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ label })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([label])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <li
      key={`${label} ${url}`}
      css={[
        tw`my-2 text-grayBlack px-5 first-of-type:pt-2 last:pb-2 leading-10`,
      ]}
    >
      <Link
        to={url}
        aria-expanded={open}
        aria-hidden={open}
        tabIndex={tabVar ? 0 : -1}
        css={[
          tw`text-grayBlack font-normal	`,
          tw`hover:text-grayBlack hover:underline`,
          tw`focus:text-grayBlack focus:underline`,
        ]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
        onBlur={() => handleBlur}
        onClick={handleClick}
      >
        {text?.label}
      </Link>
    </li>
  )
}

export default HeaderNavItem
