import { graphql, useStaticQuery } from "gatsby"

const useHeaderMenuItems = () => {
  const headerData = useStaticQuery(graphql`
    {
      wpMenu(locations: {eq: BAA_HEADER_MENU}) {
        menuItems {
          nodes {
            label
            path
            parentId
            childItems {
              nodes {
                label
                path
              }
            }
          }
        }
      }
    }
  `)

  const navItems = headerData?.wpMenu?.menuItems?.nodes?.filter(navItem => navItem?.parentId === null)
  return navItems
}

export default useHeaderMenuItems
