import React from "react"
import tw from "twin.macro"

import useButtonStates from "../../hooks/useButtonStates"

const Flights: React.FC<{
  iconStyle?: any
  parentHover?: boolean
  strokeColor?: any
  fillColor?: any
  strokeWidth?: number
}> = ({ iconStyle, parentHover, strokeColor, fillColor, strokeWidth }) => {
  const {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    handleVisited,
    hover,
    visited,
    focus,
  } = useButtonStates()

  return (
    <svg
      css={[iconStyle]}
      viewBox="50.41 11.26 44.29 42.78"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleVisited}
      style={{
        outlineWidth: focus && "1px",
        outlineColor: focus && "#9ED8D5",
        outlineOffset: focus && "1px",
        outlineStyle: focus && "dotted",
        animation: parentHover && "bounceUp 0.6s",
        animationIterationCount: parentHover && 1,
      }}
    >
      <path
        d="M86.654 14.132 78.21 21.83l-21.754-5.066-4.335 4.362 17.384 8.651-7.796 7.116-7.543-1.18-2.755 2.62 9.94 3.552 2.608 10.24 2.85-2.484-.463-7.63 7.795-7.117 6.983 18.137 4.748-3.916-3.006-22.171 8.94-8.162c1.53-1.397 2.713-4.065 1.189-5.748-1.526-1.692-4.812-.3-6.341 1.098z"
        css={[tw`transition-colors`]}
        stroke={
          strokeColor
            ? strokeColor
            : hover || parentHover
            ? "#9ED8D5"
            : visited
            ? "#FFF"
            : "#FFF"
        }
        strokeWidth={strokeWidth ?? "2"}
        fill={fillColor ?? "none"}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Flights
