import { graphql, useStaticQuery } from "gatsby"

const useNotificationData = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsNotifications {
          notifications {
            notification {
              title
              description
            }
          }
        }
      }
    }
  `)

  return data?.wp?.acfOptionsNotifications?.notifications?.notification
}

export default useNotificationData
