import React, { useEffect, useState } from "react"

import HeaderSearch from "./HeaderSearch"
import HeaderMenuNotifications from "./HeaderMenuNotifications"
import HamburgerMenu from "../Icons/HamburgerMenu"
import HeaderMenuWeather from "./HeaderMenuWeather"
import Accessibility from "../Icons/Accessibility"
import tw from "twin.macro"
import { Link } from "../Link"

const HeaderMenuRightIcons: React.FC<{
  mobileMenu: boolean
  setMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  handleMobileMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}> = ({ mobileMenu, setMobileMenu, handleMobileMenu }) => {
  const getTime = () => {
    const date = new Date()
    return (
      (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      (date.getHours() >= 12 ? "PM" : "AM")
    )
  }

  const [time, setTime] = useState(getTime)
  setInterval(() => setTime(getTime), 1000)
  useEffect(() => {
    document
      .getElementById("enableReciteMobile")
      .addEventListener("click", function () {
        loadService()
      })
  }, [])

  return (
    <>
      <HeaderMenuNotifications setMobileMenu={setMobileMenu} />
      <HeaderSearch setMobileMenu={setMobileMenu} />
      <Link
        id="enableReciteMobile"
        css={[
          tw`flex items-center transition-colors duration-500 rounded text-white px-2  2xl:hidden hover:(cursor-pointer bg-white text-black no-underline)`,
        ]}
      >
        <Accessibility styling={[tw`h-8 w-8 stroke-current`]} />
      </Link>
      <HamburgerMenu
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        handleMobileMenu={handleMobileMenu}
      />

      <HeaderMenuWeather time={time} />
    </>
  )
}

export default HeaderMenuRightIcons
