import React from "react"
import { IconProps } from "./Icon.d"

const Accessibility: React.FC<{ styling?: any; color?: string }> = ({
  styling,
  color,
  ...remainingProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      css={[styling]}
      {...remainingProps}
    >
      <path
        stroke={"inherit"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM7 9l5 1m5-1-5 1m0 0v3m0 0-2 5m2-5 2 5"
      />
      <path
        fill={"inherit"}
        stroke={"inherit"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M12 7a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z"
      />
    </svg>
  )
}

export default Accessibility
