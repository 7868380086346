import React, { useState, createContext } from "react"
import {
  AcceptedLanguage,
  LanguageContextValue,
  LanguageOption,
} from "./language.d"

export const LanguageContext = createContext<LanguageContextValue>({
  language: "en",
})

export const LanguageProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<AcceptedLanguage>("en")
  const languageOptions: Array<LanguageOption> = [
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
    { label: "German", value: "de" },
    { label: "Chinese (Simplified)", value: "zh-CN" },
    { label: "Chinese (Traditional)", value: "zh-TW" },
  ]

  return (
    <LanguageContext.Provider
      value={{ language, languageOptions, setLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
