import React from "react"

const ListDropdown: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      css={[styling]}
      width="51"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m32.842 21.154-7.692 9.23-7.693-9.23"
        stroke="#202F45"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ListDropdown
