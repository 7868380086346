import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

import HeaderSettingsLanguage from "./HeaderSettingsLanguage"
import { Link } from "../Link"
import Accessibility from "../Icons/Accessibility"

const HeaderSettings: React.FC = () => {
  const wifiText = "Free Wifi Network"
  const [text, setText] = useState({ wifiText })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ wifiText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([wifiText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  useEffect(() => {
    document
      .getElementById("enableRecite")
      .addEventListener("click", function () {
        loadService()
      })
  }, [])

  return (
    <div css={[tw`hidden xl:flex text-white self-end mt-2`]}>
      <div css={[tw`text-sm flex items-center`]}>
        <HeaderSettingsLanguage />
        <div
          css={[tw`tracking-tightest py-2 pl-5 text-3.75 font-light`]}
          aria-label="wifi"
        >
          {text?.wifiText}: <span css={[tw`font-semibold`]}>BHMFREEWIFI</span>
        </div>
        <div css={[tw`pl-5`]}>
          <Link
            id="enableRecite"
            css={[
              tw`hover:(cursor-pointer bg-white text-black no-underline) transition-colors duration-500 rounded p-2 text-white hidden 2xl:flex gap-2 items-center`,
            ]}
          >
            <Accessibility styling={[tw`h-5 w-5 stroke-current`]} />
            Accessibility Tool
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HeaderSettings
