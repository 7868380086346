/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import { Layout } from "./src/components/Layout"
import { LanguageProvider } from "./src/context/Language"
import { BlackoutMode } from "./src/components/BlackoutMode"

const noLayoutPages = ["/tenants/auth/"]

export const wrapPageElement = ({ element }) => {
  if (noLayoutPages.includes(element.key)) {
    return <LanguageProvider>{element}</LanguageProvider>
  }
  return (
    <BlackoutMode>
      <LanguageProvider>
        <Layout>{element}</Layout>
      </LanguageProvider>
    </BlackoutMode>
  )
}
