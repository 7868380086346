import React, { useState, useContext, useEffect, useRef } from "react"
import tw from "twin.macro"

import MenuArrow from "../Icons/MenuArrow"
import { Link } from "gatsby"
import useOpenDropdown from "../../hooks/useOpenDropdown"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import HeaderNavItem from "./HeaderNavItem"

const HeaderMenuItems: React.FC<{
  title: string
  options: Array<any>
  index: number
  desktop?: boolean
}> = ({ title, options, index, desktop }) => {
  const ref = useRef(null)
  const { open, setOpen, hover, setHover } = useOpenDropdown(ref)

  const handleMouseDown = () => {
    setOpen(!open)
  }

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleMouseDown()
    }
  }

  const handleBlur = e => {
    if (!ref.current.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }

  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div
      ref={ref}
      css={[tw`xl:block cursor-pointer relative`, desktop && tw`hidden`]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <button
        css={[
          tw`h-full flex items-center rounded-t pl-1.5 2xl:p-2 transition-colors duration-300 hover:bg-sand hover:text-grayBlack`,
          open && tw`bg-sand text-grayBlack`,
        ]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
        aria-label={`${open ? "close" : "open"} ${title} dropdown`}
        aria-expanded={open}
        aria-controls={`${title}Dropdown`}
        onMouseDown={handleMouseDown}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      >
        {text?.title}
        <MenuArrow
          type={open ? "up" : "down"}
          color={hover || open ? "#4b4143" : "#9ed8d5"}
        />
      </button>

      <ul
        id={`${title}Dropdown`}
        css={[
          tw`absolute z-1 bg-sand w-60 shadow-dropdown overflow-hidden transition-max-h ease-max-h-hide duration-max-h-hide max-h-0`,
          index > 2 && tw`text-left`,
          open && tw`max-h-[200rem] ease-max-h-show duration-max-h-show`,
        ]}
      >
        {options?.map(({ label, path }) => (
          <HeaderNavItem
            url={path}
            label={label}
            handleBlur={handleBlur}
            key={label}
            tabVar={open}
            handleClick={() => setOpen(!open)}
          />
        ))}
      </ul>
    </div>
  )
}

export default HeaderMenuItems
