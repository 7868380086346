import React from "react"
import tw from "twin.macro"

const BellIcon: React.FC<{
  styling?: any
  color?: string
}> = ({ styling, color }) => {
  return (
    <svg
      css={[tw`transition-colors`, styling]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m24.029 19.678-3.066-3.763-.109-6.626a.84.84 0 0 0 0-.134C19.888 3.897 16.831.89 12.456.89c-4.62 0-8.331 3.36-9.238 8.247a.84.84 0 0 0 0 .151l-.11 6.719L.816 19.77a.84.84 0 0 0 0 .84.84.84 0 0 0 .722.437h7.558a3.36 3.36 0 0 0 6.72 0h7.558a.84.84 0 0 0 .755-.479.84.84 0 0 0-.1-.89zm-11.573 3.049a1.68 1.68 0 0 1-1.68-1.68h3.36a1.68 1.68 0 0 1-1.68 1.68zm-9.432-3.36 1.68-2.72a.84.84 0 0 0 .118-.42l.075-6.854C5.661 5.3 8.693 2.57 12.456 2.57c4.728 0 6.24 4.2 6.718 6.803l.118 6.853a.84.84 0 0 0 .185.52l2.133 2.621H3.024z"
        fill={color}
      />
    </svg>
  )
}

export default BellIcon
