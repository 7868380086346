import { graphql, useStaticQuery } from "gatsby"

const useCopyrightMenuItems = () => {
  const data = useStaticQuery(graphql`
    {
        wpMenu(locations: {eq: BAA_COPYRIGHT_MENU}) {
            menuItems {
              nodes {
                label
                url
              }
            }
        }
    }
  `)

  return data?.wpMenu?.menuItems?.nodes
}

export default useCopyrightMenuItems
