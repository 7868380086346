import React, { useRef, useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { Notification, Notifications} from "../Notifications"
import BellIcon from "../Icons/BellIcon"
import Close from "../Icons/Close"
import useOpenDropdown from "../../hooks/useOpenDropdown"
import useNotificationData from "../../hooks/useNotificationData"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")

const HeaderMenuNotifications: React.FC<{
  setMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setMobileMenu }) => {
  const [read, setRead] = useState(false)
  const notifications = useNotificationData()

  const ref = useRef(null)
  const { open, setOpen, hover, setHover } = useOpenDropdown(ref)

  const handleRead = () => {
    setRead(!read)
    setOpen(!open)
    try {
      let value = ""
      notifications.forEach((notification: any) => {
        value += notification?.title
      });
      localStorage.setItem("baa_notifications", value)
    } catch(err) {
      console.log(err)
    }
  }

  const handleMouseDown = () => {
    setOpen(!open)
    setMobileMenu(false)
  }

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleMouseDown()
    }
  }

  const handleBlur = e => {
    if (!ref.current.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }

  const notificationText = "Notifications"
  const noNotificationsText = "There are no new notifications at this time."
  const markAsReadText = "Mark As Read"
  const { language } = useContext(LanguageContext)
  const [text, setText] = useState({ notificationText, noNotificationsText, markAsReadText })

  useEffect(() => {
    if (language === "en") {
      setText({ notificationText, noNotificationsText, markAsReadText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([notificationText, noNotificationsText, markAsReadText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  useEffect(() => {
    if(!notifications) return
    try {
      let value = ""
      notifications?.forEach((notification: any) => {
        value += notification?.title
      });
      const storedNotification = localStorage?.getItem("baa_notifications")
      if(storedNotification === value) setRead(true)
    } catch(err) {
      console.log(err)
      setRead(false)
    }
  }, [])

  return (
    <div
      css={[tw`relative flex items-center`]}
      ref={ref}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <button
        type="button"
        css={[
          tw`h-full w-full pl-2 pr-3 relative z-1 flex
        items-center justify-center`,
        ]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
        onMouseDown={handleMouseDown}
        aria-label={`${open ? "close" : "open"} notifications dropdown`}
        aria-expanded={open}
        aria-controls="notificationsDropdown"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      >
        {notifications?.length > 0 && !read && (
          <Notifications
            styling={tw`h-4 w-4 absolute top-2 right-2 xl:top-0 mt-1 ml-4 rounded-full bg-red text-xs`}
            number={notifications.length}
          />
        )}
        <BellIcon
          styling={[tw`w-6 h-6`]}
          color={open || hover ? "#4b4143" : "#FFF"}
        />
      </button>
      <div
        css={[
          tw`h-full w-full left-1/2 top-0 -translate-x-1/2 absolute overflow-hidden`,
        ]}
      >
        <div
          css={[
            tw`h-full w-full absolute left-0 top-full duration-300 rounded-t-md bg-sand`,
            (open || hover) && tw`top-0`,
          ]}
        />
      </div>
      <div
        id="notificationsDropdown"
        css={[
          tw`w-screen xxs:w-88 absolute -right-36 xxs:-right-16 xl:-left-16 top-full z-20 pt-8 xs:pt-0 bg-sand shadow-dropdown opacity-0 invisible xl:opacity-100 overflow-hidden xl:max-h-0`,
          open && tw`opacity-100 visible xl:max-h-screen`,
        ]}
        style={
          open
            ? {
                transition:
                  "opacity 0.3s, visibility 0s, max-height 0.5s ease-in-out",
              }
            : {
                transition:
                  "opacity 0.3s, visibility 0s 0.3s, max-height 0.75s cubic-bezier(0, 1, 0, 1)",
              }
        }
        tabIndex={!open && -1}
      >
        <div css={[tw`px-4.25 pt-6 pb-12 relative`]}>
          <p css={[tw`font-extrabold text-grayBlack mb-4`]}>{text?.notificationText}</p>
          <button
            css={[tw`absolute right-4.25 top-6`]}
            onMouseDown={handleMouseDown}
            aria-label="Close Notifications Dropdown"
            style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
            onBlur={handleBlur}
          >
            <Close color="black" iconStyle={tw`w-6 h-6`} />
          </button>
          {!read && (
            <>
            { notifications?.map(({ title, description }, i) =>(
              <Notification title={title} description={description} key={i} />
            ))}
            </>
          )} 
          { (!notifications || read) && (
            <p css={tw`text-grayBlack`}>{text?.noNotificationsText}</p>
          )

          }
          {!read && notifications?.length > 0 && (
            <button
              css={[
                tw`absolute right-6 bottom-6 font-bold text-sm text-blue-250 cursor-pointer`,
              ]}
              type="button"
              onClick={handleRead}
              aria-label="Mark notifications as Read"
              style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
              onBlur={handleBlur}
            >
              {text?.markAsReadText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeaderMenuNotifications
