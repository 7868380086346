import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { IImageProps } from "./Image.d"

const Image: React.FC<IImageProps> = ({
  src,
  alt,
  style,
  imgStyle,
  publicURL,
  ...remainingProps
}) => {
  if (!src && publicURL) {
    return <img src={publicURL} alt={alt ? alt : ""} {...remainingProps} />
  }

  switch (typeof src) {
    case "string":
      return <img src={src} alt={alt ? alt : ""} {...remainingProps} />
    default:
      return (
        <GatsbyImage
          image={src}
          alt={alt ? alt : ""}
          style={style}
          imgStyle={imgStyle}
          {...remainingProps}
        />
      )
  }
}

export default Image
