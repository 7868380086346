import { graphql, useStaticQuery } from "gatsby"

const useFooterData = () => {
  const footerData = useStaticQuery(graphql`
    {
      allWp {
        nodes {
          siteSettings {
            footer {
              address
              backgroundImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 1920)
                  }
                }
              }
              email {
                title
                url
              }
              phone {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  return footerData.allWp.nodes[0].siteSettings.footer
}

export default useFooterData
