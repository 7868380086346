class TranslateClient {
  public static translate = async (text: string[], targetLanguage: string) => {
    try {
      const res = await fetch(`${process.env.GATSBY_BAA_API_URL}/translate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.GATSBY_BAA_API_KEY,
        },
        body: JSON.stringify({
          text: text,
          target: targetLanguage,
        }),
      })
      return await res.json()
    } catch (err) {
      console.log(`error in translation: ${err}`)
    }
  }
}

export default TranslateClient
