import { graphql, useStaticQuery } from "gatsby"

const useFooterMenuItems = () => {
  const footerData = useStaticQuery(graphql`
    {
      allWpMenuItem(
        filter: {
          locations: { eq: BAA_FOOTER_PRIMARY_MENU }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            label
            path
          }
        }
      }
    }
  `)

  return footerData.allWpMenuItem.edges
}

export default useFooterMenuItems
