import { useState } from "react"

const useButtonStates = () => {
  const [hover, setHover] = useState(false)
  const [visited, setVisited] = useState(false)
  const [focus, setFocus] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleMouseOver = () => {
    setHover(true)
  }
  const handleMouseLeave = () => {
    setHover(false)
  }
  const handleFocus = () => {
    setFocus(true)
  }
  const handleBlur = () => {
    setFocus(false)
  }
  const handleVisited = () => {
    setVisited(false)
  }
  const handleLoading = () => {
    setLoading(true)
  }

  return {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    handleVisited,
    hover,
    setHover,
    visited,
    setVisited,
    focus,
    setFocus,
    loading,
    handleLoading,
  }
}

export default useButtonStates
