import React, { useState, useContext, useEffect } from "react"
import { MenuIconProps } from "./MenuIcon.d"
import { Link } from "gatsby"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import tw from "twin.macro"
import Flights from "../Icons/Flights"
import Parking from "../Icons/Parking"
import TerminalMap from "../Icons/TerminalMap"
import DineShop from "../Icons/DineShop"

const MenuIcon: React.FC<MenuIconProps> = ({
  label,
  path,
  quickLinksIcons,
  index,
  styling,
}) => {
  const [hover, setHover] = useState(null)
  const { language } = useContext(LanguageContext)
  const [text, setText] = useState({ label })
  const icons = {
    "airplane": Flights,
    "parking": Parking,
    "map": TerminalMap,
    "shopping-bag": DineShop,
  }
  const TagName = icons[quickLinksIcons?.icon]

  useEffect(() => {
    if (language === "en") {
      setText({ label })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([label])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <Link
      key={index}
      to={path}
      css={[
        tw`flex flex-col text-center text-white sm:mx-5 -mx-2 sm:-mx-0 text-sm sm:text-base`,
        styling,
        hover === index && tw`text-teal`,
      ]}
      aria-label={label}
      className="group"
      onMouseOver={() => setHover(index)}
      onMouseLeave={() => setHover(null)}
      style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
    >
      {TagName && (
        <TagName
          iconStyle={tw`w-11 pb-1 mx-auto`}
          parentHover={hover === index}
        />
      )}
      {text?.label}
    </Link>
  )
}

export default MenuIcon
