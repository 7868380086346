import React from "react"
import tw from "twin.macro"

import MenuIcons from "../MenuIcons/MenuIcons"

const FooterMenu: React.FC = () => {
  return (
    <div
      css={[
        tw`left-0 bottom-0 overflow-x-hidden flex flex-row place-content-evenly bg-gradient-to-b from-blue-200 to-blue-300 text-white py-4 md:py-8 xl:py-12`,
      ]}
    >
      <MenuIcons />
    </div>
  )
}

export default FooterMenu
