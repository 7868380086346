import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import useSocialLinks from "../../hooks/useSocialLinks"
import SectionHeading from "../SectionHeading/SectionHeading"
import SocialIcons from "../SocialIcons/SocialIcons"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

const FooterSocials: React.FC = () => {
  const { headline } = useSocialLinks()
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div css={[tw`text-center mt-16 md:mt-32`]}>
      <SectionHeading line={false}>{text?.headline}</SectionHeading>
      <SocialIcons
        ulStyling={tw`flex flex-row justify-center mt-10 mb-20 xl:mb-10`}
        liStyling={tw`pl-7 first-of-type:pl-0`}
      />
    </div>
  )
}

export default FooterSocials
