import React from "react"
import tw from "twin.macro"

import SocialIcons from "../SocialIcons/SocialIcons"
import MenuContent from "./MenuContent"
import FooterMenu from "../Footer/FooterMenu"
import MobileMenuLanguage from "./MobileMenuLanguage"

const MobileMenu: React.FC<{
  mobileMenu?: boolean
  handleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ mobileMenu, handleMobileMenu }) => (
  <div
    id="mobileMenu"
    css={[
      tw`absolute left-0 top-16 w-screen bg-sand text-grayBlack block xl:hidden z-10 opacity-0 invisible shadow-dropdown overflow-y-auto h-mobileMenu`,
      mobileMenu && tw`opacity-100 visible`,
    ]}
    style={
      mobileMenu
        ? { transition: "opacity 0.3s, visibility 0s" }
        : { transition: "opacity 0.3s, visibility 0s 0.3s" }
    }
  >
    <nav
      aria-label="site mobile"
      css={[tw`flex flex-col justify-between mt-10 h-mobileMenu`]}
    >
      <div>
        <MobileMenuLanguage />
        <MenuContent handleMobileMenu={handleMobileMenu} />
      </div>
      <div css={[tw`justify-self-end`]}>
        <div css={[tw`pt-3 text-center border-t border-lightGray`]}>
          Free Wifi Network:{" "}
          <span css={[tw`text-blue-250 font-medium`]}>BHMFREEWIFI</span>
        </div>
        <SocialIcons
          ulStyling={tw`flex flex-row justify-center my-6 bg-sand`}
          liStyling={tw`pl-7 first-of-type:pl-0`}
        />
        <FooterMenu />
      </div>
    </nav>
  </div>
)

export default MobileMenu
