/* eslint-disable indent */
import React, { useRef, useState, useEffect } from "react"
import tw from "twin.macro"
import { SearchBar, SearchResult } from "../Search"
import Close from "../Icons/Close"
import Search from "../Icons/Search"
import useOpenDropdown from "../../hooks/useOpenDropdown"
import { Link } from "gatsby"
import { StyledButton } from "../Button"
import SearchClient from "../../clients/SearchClient"
import Loader from "../Icons/Loader"
import { getSlugFromUrl } from "../../helpers"

const HeaderSearch: React.FC<{
  setMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setMobileMenu }) => {
  const [popularSearchQueries, setPopularSearchQueries] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)

  const ref = useRef(null)
  const { open, setOpen, hover, setHover, handleOpen } = useOpenDropdown(ref)

  const handleMouseDown = () => {
    setOpen(!open)
    setMobileMenu(false)
  }

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleMouseDown()
    }
  }

  const handleBlur = (e: { relatedTarget: any }) => {
    if (!ref.current.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }

  const search = async (query: string) => {
    if (!query) return

    setSearchQuery(query)
    setLoading(true)
    setSearchResults([])

    try {
      const data = await SearchClient.getSearchResults(query)
      console.log(data, "data")

      if (data && data.records && data.records.posts) {
        console.log(data, data.records.posts)
        setLoading(false)
        setSearchResults(data.records.posts)
      } else {
        setLoading(false)
        setSearchResults(["No search results were found."])
      }
    } catch (error) {
      console.error("Error fetching search results:", error)
      setLoading(false)
      setSearchResults(["An error occurred while fetching search results."])
    }
  }

  useEffect(() => {
    if (!open) return

    if (popularSearchQueries?.length === 0) {
      setLoading(true)
      ;(async () => {
        const queries = await SearchClient.getPopularSearchQueries()
        if (queries) {
          setLoading(false)
          setPopularSearchQueries(queries)
        }
      })()
    }
  }, [open])

  return (
    <div
      ref={ref}
      style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      css={tw`text-grayBlack`}
    >
      <div css={tw`h-14 xl:h-full w-full relative`}>
        <StyledButton
          navDropdownIcon={true}
          css={[
            tw`h-14 xl:h-full w-full relative px-4 relative z-10 xl:px-2 xl:py-2`,
          ]}
          aria-expanded={open}
          aria-controls="searchDropdown"
          aria-label="Show Search Bar"
          style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
          onMouseDown={handleMouseDown}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        >
          <Search
            css={[
              tw`h-6 w-6 relative z-10`,
              open || hover ? "color: #4b4143" : "color: #FFF",
            ]}
            color={open || hover ? "#4b4143" : "#FFF"}
          />
        </StyledButton>
        <div
          css={[
            tw`h-full w-full left-1/2 top-0 -translate-x-1/2 absolute overflow-hidden z-0`,
          ]}
        >
          <div
            css={[
              tw`h-full w-full absolute left-0 top-full duration-300 rounded-t-md bg-sand`,
              (open || hover) && tw`top-0`,
            ]}
          />
        </div>
      </div>

      <div
        id="searchDropdown"
        css={[
          tw`absolute top-full left-0 z-20 w-screen bg-sand opacity-0 invisible xl:opacity-100 xl:overflow-hidden xl:max-h-0`,
          open && tw`opacity-100 visible xl:max-h-screen`,
        ]}
        style={
          open
            ? {
                transition:
                  "opacity 0.3s, visibility 0s, max-height 0.5s ease-in-out",
              }
            : {
                transition:
                  "opacity 0.3s, visibility 0s 0.3s, max-height 0.75s cubic-bezier(0, 1, 0, 1)",
              }
        }
      >
        <div css={[tw`w-full hidden xl:flex justify-end m-auto max-w-7xl`]}>
          <button
            css={[tw`flex justify-center items-center mr-10 mt-7`]}
            onClick={handleOpen}
            aria-label="Hide Search Bar"
            style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
            onBlur={handleBlur}
            tabIndex={!open && -1}
          >
            <Close color="black" iconStyle={tw`w-6 h-6`} aria-label="close" />
          </button>
        </div>
        <div
          css={[tw`flex flex-col p-5 pt-16 xl:p-0 xl:pb-10 mx-auto max-w-5xl`]}
        >
          <div css={[tw`flex flex-row justify-center pb-5 xl:pb-11 xl:pb-12`]}>
            <SearchBar
              placeholder="Search BHM"
              setSearchQuery={search}
              css={tw`w-full`}
              handleBlur={handleBlur}
            />
          </div>
          {Array.isArray(popularSearchQueries) &&
            popularSearchQueries?.length > 0 &&
            !searchResults &&
            !loading && (
              <div css={[tw`text-xs text-flatBlack px-1`]}>
                <p css={[tw`text-xs`]}>POPULAR SEARCHES</p>
                {popularSearchQueries?.map((query: string, i: number) => {
                  console.log(query, "-------")
                  if (i > 8) return
                  return (
                    <button
                      key={i}
                      value={query}
                      css={[tw`text-4.25 my-5 block`]}
                      onClick={() => search(query)}
                    >
                      {query}
                    </button>
                  )
                })}
              </div>
            )}
          {searchResults && (
            <div css={tw`text-flatBlack px-1`}>
              {searchResults.length === 0 ? (
                <p css={tw`text-xs uppercase`}>
                  {searchResults.length} RESULTS FOR &apos;{searchQuery}
                  &apos;
                </p>
              ) : (
                <>
                  <p css={tw`text-xs uppercase`}>
                    TOP {searchResults.length} RESULTS FOR &apos;{searchQuery}
                    &apos;
                  </p>
                  {searchResults?.map(({ url, title }, i: number) => {
                    if (i >= 5) {
                      console.log("Search Results:", searchResults, url, title)
                      return
                    } else {
                      return (
                        <SearchResult
                          url={getSlugFromUrl(url)}
                          title={title}
                          key={i}
                          onClick={() => setOpen(false)}
                          css={[tw`text-4.25 text-flatBlack my-5 block`]}
                        />
                      )
                    }
                  })}
                  <Link
                    to="/search"
                    css={[
                      tw`text-blue-250 text-lg font-extrabold hover:underline focus:underline`,
                    ]}
                    style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
                    onBlur={handleBlur}
                    onClick={() => setOpen(!open)}
                    state={{
                      results: searchResults,
                      query: searchQuery,
                      prevPath: location.pathname,
                    }}
                  >
                    View All Results
                  </Link>
                </>
              )}
            </div>
          )}
          {loading && <Loader css={tw`text-blue-250 m-auto w-8 h-8`} />}
        </div>
      </div>
    </div>
  )
}

export default HeaderSearch
