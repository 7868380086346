import { Link } from "gatsby"
import styled, { StyledComponent } from "@emotion/styled"
import tw from "twin.macro"
import { IButtonAProps, IButtonLinkProps, IButtonProps } from "./Button.d"

export const StyledButton: StyledComponent<IButtonProps> = styled.button(
  ({ primary, navDropdownIcon }) => [
    tw`inline-block text-center text-sm`,

    primary && tw`uppercase text-center min-w-60 mb-2 px-8 py-3 text-grayBlack text-base font-extrabold rounded-3xl bg-teal bg-leftBottom transition transition-[background-position] duration-1000`,
    primary && "background: linear-gradient(to right, #9ed8d5 50%, #178C88 50%); background-size: 200% 100%; outline-color: #178C88; outline-offset: 5px",
    // Hover
    primary && tw`hover:text-white hover:bg-left-full hover:!no-underline`,
    // Focus
    primary && tw`focus-visible:text-white focus-visible:bg-darkTeal focus-visible:bg-none `,
    // Active
    primary && tw`active:bg-teal active:bg-none active:text-grayBlack`,


    navDropdownIcon && tw`px-4 xl:px-2 xl:py-2 rounded-t text-white`,
    // Hover
    navDropdownIcon && tw`hover:text-grayBlack`,
    // Focus
    navDropdownIcon && tw`focus:text-grayBlack`,
    // Active
    navDropdownIcon && tw`active:text-grayBlack`,
  ]
)
export const ButtonLink: StyledComponent<IButtonLinkProps> =
  StyledButton.withComponent(Link)

export const ButtonA: StyledComponent<IButtonAProps> =
  StyledButton.withComponent("a")

export default StyledButton
