import { graphql, useStaticQuery } from "gatsby"

const useQuickLinksNavItems = () => {
  const quickLinksData = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: BAA_BUSINESS_MENU }) {
        menuItems {
          nodes {
            label
            path
            quickLinksIcons {
              icon
            }
          }
        }
      }
    }
  `)

  return quickLinksData?.wpMenu?.menuItems?.nodes
}

export default useQuickLinksNavItems
