import React from "react"
import useButtonStates from "../../hooks/useButtonStates"

const Parking: React.FC<{ iconStyle?: any; parentHover: boolean }> = ({
  iconStyle,
  parentHover,
}) => {
  const {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    handleVisited,
    hover,
    visited,
    focus,
  } = useButtonStates()

  return (
    <svg
      css={[iconStyle]}
      viewBox="50.41 11.26 44.29 42.78"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleVisited}
      style={{
        outlineWidth: focus && "1px",
        outlineColor: focus && "#9ED8D5",
        outlineOffset: focus && "1px",
        outlineStyle: focus && "dotted",
        animation: parentHover && "bounceUp 0.6s",
        animationIterationCount: parentHover && 1,
      }}
    >
      <g fill={hover || parentHover ? "#9ED8D5" : visited ? "#FFF" : "#FFF"}>
        <path d="M81.524 36.857a3.81 3.81 0 1 0 0 7.62 3.81 3.81 0 0 0 0-7.62zm0 5.491a1.683 1.683 0 1 1 .001-3.365 1.683 1.683 0 0 1-.001 3.365zm-22.858-5.491a3.81 3.81 0 1 0 0 7.619 3.81 3.81 0 0 0 0-7.619zm0 5.491a1.683 1.683 0 1 1 .002-3.365 1.683 1.683 0 0 1-.002 3.365zM90.99 14H76.82c-1.083 0-1.963.832-1.963 1.86v13.422c0 1.028.88 1.86 1.963 1.86h14.17c1.083 0 1.962-.832 1.962-1.86V15.86c0-1.028-.88-1.86-1.963-1.86zm-.028 13.84c0 .804-.69 1.454-1.54 1.454H78.313c-.85 0-1.54-.65-1.54-1.453V17.36c0-.802.69-1.453 1.54-1.453h11.109c.85 0 1.54.65 1.54 1.453v10.48z" />
        <path d="M80.704 18.074c.662-.113 1.593-.198 2.904-.198 1.327 0 2.272.255 2.907.764.607.482 1.015 1.274 1.015 2.21 0 .932-.31 1.725-.874 2.263-.734.693-1.82 1.004-3.09 1.004-.281 0-.535-.013-.732-.04v3.409h-2.13v-9.412zm2.13 4.332c.182.042.408.056.718.056 1.142 0 1.848-.58 1.848-1.556 0-.878-.607-1.402-1.68-1.402-.437 0-.733.043-.887.085v2.817zm4.362 11.547H55.894l6.495-10.909s.235-.447 1.33-.447h6.72s1.595.105 1.537-.97c-.06-.57-.382-.96-1.077-.96h-8.94s-.422.018-.88.789c-.411.685-6.154 10.357-7.435 12.525-.93.161-1.644 1.045-1.644 2.121v9.9c0 1.198.871 2.15 1.946 2.15h1.948v4.745c0 .589.391 1.064.88 1.064h3.14c.478 0 .88-.475.88-1.064v-4.745h19.603v4.782c0 .59.392 1.066.871 1.066h3.101c.48 0 .87-.475.87-1.066v-4.782h1.957c1.077 0 1.947-.952 1.947-2.15v-9.9c0-1.19-.87-2.15-1.947-2.15zm-.01 10.745c0 .866-.772 1.56-1.73 1.56h-29.61c-.96 0-1.732-.694-1.732-1.56v-7.17c0-.856.772-1.56 1.731-1.56h29.61c.959 0 1.731.704 1.731 1.56v7.17z" />
      </g>
    </svg>
  )
}

export default Parking
