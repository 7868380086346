import React from "react"
import tw from "twin.macro"
import { Image } from "../Image"

const FooterBackground: React.FC<{
  backgroundImageSrc: any
}> = ({ backgroundImageSrc }) => (
  <div
    css={[
      tw`relative lg:absolute right-0 bottom-0 lg:bottom-32 xl:bottom-40 w-screen h-64 sm:h-96 md:h-120 lg:h-160 max-w-8xl -z-1 shadow-insetWhite`,
    ]}
  >
    <div
      css={[tw`block absolute left-0 top-0 h-25 w-full lg:bg-transparent`]}
      style={{
        background:
          "linear-gradient( to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) )",
      }}
    />
    <div
      css={[tw`block absolute left-0 bottom-0 h-full w-full lg:border-l-25`]}
      style={{
        borderImage:
          "linear-gradient( to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) ) 1 100%",
      }}
    />
    <Image
      src={backgroundImageSrc}
      alt=""
      css={[
        tw`hidden lg:block absolute left-0 bottom-0 w-full h-full object-right-bottom object-cover -z-1`,
      ]}
      aria-hidden
    />
    <Image
      src="/footerMobileImage.webp"
      css={[
        tw`absolute lg:hidden w-full h-full object-right-bottom object-cover -z-1`,
      ]}
      alt=""
      aria-hidden
    />
  </div>
)

export default FooterBackground
