import React from "react"

const MostlySunny: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      css={[styling]}
      fill="#fff"
    >
      <path
        d="M3.09,13.43c0,0.27,0.09,0.49,0.28,0.67s0.43,0.26,0.72,0.26h1.69c0.27,0,0.5-0.09,0.69-0.27s0.29-0.4,0.29-0.66
	c0-0.29-0.09-0.53-0.28-0.71s-0.42-0.28-0.69-0.28H4.09c-0.29,0-0.53,0.09-0.72,0.28C3.18,12.89,3.09,13.13,3.09,13.43z M4.86,19.61
	c0,0.97,0.35,1.81,1.06,2.52c0.71,0.71,1.54,1.06,2.51,1.06h6.86c0.97,0,1.8-0.35,2.49-1.05c0.69-0.7,1.04-1.54,1.04-2.53
	c0-0.48-0.07-0.89-0.21-1.23c0.83-0.53,1.49-1.24,1.97-2.12c0.48-0.88,0.73-1.83,0.73-2.84c0-0.81-0.16-1.59-0.48-2.33
	c-0.32-0.74-0.75-1.38-1.28-1.91c-0.53-0.53-1.17-0.96-1.91-1.28c-0.74-0.32-1.51-0.48-2.32-0.48c-1.09,0-2.1,0.27-3.02,0.81
	s-1.65,1.27-2.18,2.18c-0.53,0.92-0.79,1.92-0.79,3.01v0.34c-1,0.57-1.66,1.4-1.98,2.49c-0.76,0.24-1.36,0.66-1.81,1.27
	C5.09,18.13,4.86,18.83,4.86,19.61z M6.42,5.45c0,0.28,0.09,0.51,0.26,0.67l1.61,1.69c0.47,0.36,0.94,0.36,1.41,0
	c0.19-0.19,0.28-0.42,0.28-0.7c0-0.27-0.09-0.5-0.28-0.7L8.05,4.76C7.84,4.57,7.6,4.47,7.35,4.47c-0.28,0-0.51,0.09-0.68,0.28
	C6.5,4.94,6.42,5.17,6.42,5.45z M6.84,19.61c0-0.42,0.13-0.78,0.4-1.08c0.27-0.3,0.61-0.47,1.02-0.51l0.62-0.08
	c0.13,0,0.2-0.08,0.2-0.23l0.09-0.56c0.07-0.58,0.31-1.06,0.73-1.44c0.42-0.39,0.91-0.58,1.48-0.58c0.58,0,1.09,0.19,1.51,0.58
	c0.43,0.39,0.68,0.87,0.75,1.44l0.08,0.65c0.06,0.15,0.14,0.23,0.24,0.23h1.32c0.43,0,0.8,0.16,1.12,0.47
	c0.32,0.31,0.47,0.68,0.47,1.12c0,0.45-0.16,0.83-0.47,1.15s-0.69,0.48-1.12,0.48H8.43c-0.45,0-0.83-0.16-1.13-0.48
	C6.99,20.45,6.84,20.06,6.84,19.61z M11.26,13.22c0.07-1.09,0.49-2.01,1.27-2.76c0.77-0.74,1.71-1.12,2.79-1.12
	c1.11,0,2.06,0.4,2.84,1.19c0.78,0.79,1.17,1.76,1.17,2.89c0,0.7-0.17,1.35-0.51,1.95c-0.34,0.6-0.8,1.08-1.38,1.45
	c-0.59-0.49-1.27-0.73-2.03-0.73c-0.29-0.88-0.81-1.57-1.54-2.09c-0.73-0.52-1.56-0.78-2.48-0.78H11.26z M14.35,4.47
	c0,0.27,0.1,0.51,0.29,0.7c0.19,0.19,0.42,0.29,0.69,0.29c0.28,0,0.51-0.1,0.7-0.29c0.19-0.19,0.29-0.42,0.29-0.7V2.13
	c0-0.26-0.1-0.48-0.29-0.66c-0.19-0.18-0.42-0.27-0.7-0.27c-0.27,0-0.5,0.09-0.69,0.27c-0.19,0.18-0.29,0.4-0.29,0.66V4.47z
	 M20.67,19.7c0,0.27,0.09,0.5,0.27,0.7l1.64,1.62c0.42,0.42,0.89,0.42,1.41,0c0.18-0.17,0.26-0.39,0.26-0.68
	c0-0.27-0.09-0.49-0.26-0.67L22.3,19c-0.18-0.17-0.41-0.25-0.68-0.25c-0.28,0-0.5,0.09-0.68,0.27C20.76,19.2,20.67,19.43,20.67,19.7
	z M20.67,7.09c0,0.28,0.09,0.52,0.27,0.72c0.18,0.18,0.41,0.27,0.68,0.27c0.27,0,0.5-0.09,0.68-0.27l1.69-1.69
	c0.18-0.17,0.26-0.39,0.26-0.67c0-0.28-0.1-0.51-0.29-0.69s-0.42-0.28-0.7-0.28c-0.26,0-0.49,0.1-0.68,0.29L20.94,6.4
	C20.76,6.58,20.67,6.81,20.67,7.09z M23.25,13.43c0,0.27,0.09,0.49,0.28,0.67s0.43,0.26,0.72,0.26h1.69c0.27,0,0.5-0.09,0.69-0.27
	s0.29-0.4,0.29-0.66c0-0.29-0.09-0.53-0.28-0.71s-0.42-0.28-0.69-0.28h-1.69c-0.29,0-0.53,0.09-0.72,0.28
	C23.35,12.89,23.25,13.13,23.25,13.43z"
      />
    </svg>
  )
}

export default MostlySunny
