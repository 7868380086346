import React from "react"
import useSocialLinks from "../../hooks/useSocialLinks"
import Facebook from "../Icons/Facebook"
import Instagram from "../Icons/Instagram"
import Twitter from "../Icons/Twitter"
import { Link } from "../Link"

const SocialIcons: React.FC<{
  ulStyling: any
  liStyling: any
}> = ({ ulStyling, liStyling }) => {
  const socialLinks = useSocialLinks()
  const icons = {
    facebook: Facebook,
    twitter: Twitter,
    instagram: Instagram,
  }
  return (
    <ul css={[ulStyling]}>
      {socialLinks?.socials?.map(
        ({ name, url }: { name: string; url: string }) => {
          const Icon: React.FC = icons[name]
          return (
            <li key={name} css={[liStyling]}>
              <Link to={url}>
                <Icon />
              </Link>
            </li>
          )
        }
      )}
    </ul>
  )
}

export default SocialIcons
