const getPagePath = (parentSlug, pageSlug) => {
  parentSlug = parentSlug ? `/${parentSlug}` : ""
  return `${parentSlug}/${pageSlug}/`
}

const getRestaurantPath = restaurantSlug => {
  return `/dining/${restaurantSlug}/`
}

const getShopPath = shopSlug => {
  return `/shopping/${shopSlug}/`
}

const getDineShopPath = (nodeType, slug) => {
  if (!nodeType) return ""
  return `${
    nodeType.toLowerCase() === "restaurant" ? "/dining/" : "/shopping/"
  }${slug}`
}

const slugify = stringInput => {
  return stringInput.toLowerCase().replace(/\W+/g, "-")
}

const getSlugFromUrl = urlInput => {
  if (!urlInput) {
    console.error("Invalid URL: URL is undefined or null")
    return null
  }

  try {
    const url = new URL(urlInput)
    return url.pathname
  } catch (error) {
    console.error("Failed to construct 'URL':", error)
    return null
  }
}

const getStringValuesArray = obj => {
  let values = Object.values(obj)
  values = values.map(value => {
    if (value === null || value === undefined) return ""
    return value.toString()
  })
  return values
}

const mapValuesToObject = (obj, newValues) => {
  if (!newValues || !obj) return
  const data = Object.entries(obj).map(([key], i) => [key, newValues[i]])
  return Object.fromEntries(data)
}

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

module.exports = {
  getPagePath,
  getRestaurantPath,
  getShopPath,
  slugify,
  getSlugFromUrl,
  getStringValuesArray,
  mapValuesToObject,
  getDineShopPath,
  validateEmail,
}
