import React from "react"
import useButtonStates from "../../hooks/useButtonStates"

const TerminalMap: React.FC<{ iconStyle?: any; parentHover?: boolean }> = ({
  iconStyle,
  parentHover,
}) => {
  const {
    handleMouseOver,
    handleMouseLeave,
    handleFocus,
    handleBlur,
    handleVisited,
    hover,
    visited,
    focus,
  } = useButtonStates()

  return (
    <svg
      css={[iconStyle]}
      viewBox="50.41 11.26 44.29 42.78"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleVisited}
      style={{
        outlineWidth: focus && "1px",
        outlineColor: focus && "#9ED8D5",
        outlineOffset: focus && "1px",
        outlineStyle: focus && "dotted",
        animation: parentHover && "bounceUp 0.6s",
        animationIterationCount: parentHover && 1,
      }}
    >
      <g
        transform="translate(60 14)"
        stroke={hover || parentHover ? "#9ED8D5" : visited ? "#FFF" : "#FFF"}
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12.653" cy="12.653" r="4.49" />
        <path d="M22.992 18.14c-2.523 4.146-10.747 16.146-10.747 16.146s-8.224-12-10.747-16.146a11.35 11.35 0 0 1-1.495-5.89C.003 5.483 5.483 0 12.245 0c6.761 0 12.242 5.484 12.242 12.25a11.35 11.35 0 0 1-1.495 5.89z" />
        <path d="M16.533 28.571c3.13.928 5.508 2.992 5.508 5.445 0 3.291-4.193 5.984-9.388 5.984-5.194 0-9.388-2.693-9.388-5.984 0-2.423 1.94-4.17 5.195-5.097" />
      </g>
    </svg>
  )
}

export default TerminalMap
