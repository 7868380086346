import { Interpolation, Theme } from "@emotion/react"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import useButtonStates from "../../hooks/useButtonStates"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface Props {
  title: string
  target?: string
  url: string
  disabled?: boolean
  css?: Interpolation<Theme>
}

const ExternalTextLink: React.FC<Props> = props => {
  const { title, target, url, disabled, css } = props
  const { handleFocus, handleBlur, focus } = useButtonStates()

  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <a
      className="group"
      onFocus={handleFocus}
      onBlur={handleBlur}
      css={[
        tw`outline-none p-2 -ml-2 self-start`,
        focus && tw`border border-dotted border-blue-250 mt-[-1px]`,
        css,
        disabled && tw`pointer-events-none`,
      ]}
      target="__blank"
      title={title}
      href={url}
    >
      <span
        css={[
          tw`text-blue-250 group-hover:text-blue-300 group-active:underline`,
          focus && tw`ml-[-1px]`,
          disabled && tw`opacity-40`,
        ]}
      >
        {text?.title}
      </span>
      <svg
        css={[
          tw`inline transition-all ml-2 group-hover:ml-4 text-blue-250 group-hover:text-blue-300 fill-current stroke-current`,
          focus && tw`mt-[1px]`,
          disabled && tw`opacity-40`,
        ]}
        width="16"
        height="12"
        viewBox="0 0 16 12"
      >
        <path
          d="m9.134.762 5.152 4.774-4.534 5.449a.872.872 0 0 1-1.247.009.872.872 0 0 1-.062-1.169l2.944-2.95H2.248A.872.872 0 0 1 1.373 6a.872.872 0 0 1 .875-.875h9.14L8.504 2.244a.871.871 0 0 1-.15-1.036c.284-.326.548-.437.779-.446z"
          strokeWidth=".5"
        />
      </svg>
    </a>
  )
}

export default ExternalTextLink
