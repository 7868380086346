import React from "react"
import tw from "twin.macro"
import Search from "../Icons/Search"
import { SearchButtonProps } from "./Search.d"

const SearchButton: React.FC<SearchButtonProps> = ({
  handleClick,
  handleOnKeyDown,
  ...remainingProps
}) => {
  return (
    <button
      css={[
        tw`bg-teal rounded-r-md h-14 w-20 overflow-hidden text-grayBlack`,
        tw`focus:text-white hover:text-white active:text-white focus:outline-none focus:bg-blue-175 hover:bg-blue-175`,
        "group",
      ]}
      type="submit"
      onClick={handleClick}
      onKeyDown={handleOnKeyDown}
      {...remainingProps}
    >
      <Search
        styling={tw`h-6 w-6 mx-auto my-3.5 group-hover:text-white group-focus:text-white group-active:text-white`}
        aria-label="search"
      />
    </button>
  )
}

export default SearchButton
