import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { H2 } from "../Typography"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
interface ISectionHeading {
  children: string
  textStyles?: any
  line?: boolean
  seasonalText?: string
}

const SectionHeading: React.FC<ISectionHeading> = ({
  children,
  textStyles,
  seasonalText,
  line = true,
  ...remainingProps
}) => {
  const [text, setText] = useState({ seasonalText })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ seasonalText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([seasonalText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`flex items-start justify-center mx-4`]} {...remainingProps}>
      <div
        css={[tw`border-b border-lightGray mt-5 w-60`, !line && tw`hidden`]}
      />
      <div css={[tw`flex flex-wrap justify-center`]}>
        <H2
          css={[
            tw`min-w-52 mx-5 text-3xl font-extrabold text-center`,
            textStyles,
          ]}
        >
          {children}
        </H2>
        {seasonalText && (
          <span css={[tw`mr-5 text-sm mt-2`]}>{text?.seasonalText}</span>
        )}
      </div>
      <div
        css={[tw`border-b border-lightGray mt-5 w-60`, !line && tw`hidden`]}
      />
    </div>
  )
}

export default SectionHeading
