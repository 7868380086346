import React, { useState, useContext, useEffect } from "react"
import { NotificationProps } from "./Notifications.d"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers.js")

const Notification: React.FC<NotificationProps> = ({ title, description }) => {
  const [text, setText] = useState({ title, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div css={[tw`mt-7 px-8 relative`]}>
      <div
        css={[tw`absolute left-1.5 top-1.5 w-3.5 h-3.5 rounded-full bg-red`]}
      />
      <p css={[tw`text-flatBlack text-4.25 font-semibold mb-1.5`]}>
        {text?.title}
      </p>
      <p
        css={[tw`text-flatBlack text-sm`]}
        dangerouslySetInnerHTML={{ __html: text?.description }}
      />
    </div>
  )
}

export default Notification
