import React from "react"
import useHeaderMenuItems from "../../hooks/useHeaderMenuItems"
import tw from "twin.macro"

import HeaderMenuItems from "./HeaderMenuItems"
import HeaderMenuRightIcons from "./HeaderMenuRightIcons"

const HeaderMenu: React.FC<{
  mobileMenu: boolean
  handleMobileMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
  setMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ mobileMenu, handleMobileMenu, setMobileMenu }) => {
  const headerData = useHeaderMenuItems()
  const halfwayIndex = Math.ceil(headerData?.length / 2)
  const leftMenu = headerData?.slice(0, halfwayIndex)
  const rightMenu = headerData?.slice(halfwayIndex)

  return (
    <nav
      aria-label="site"
      css={[
        tw`h-full xl:w-full xl:h-max self-end xl:self-center flex flex-row justify-between`,
      ]}
    >
      <div css={[tw`hidden xl:flex xl:flex-row mr-28 w-1/2 justify-between`]}>
        {leftMenu?.map(({ label, childItems }, i) => {
          return (
            <HeaderMenuItems
              key={i}
              title={label}
              options={childItems.nodes}
              index={i}
            />
          )
        })}
      </div>
      <div
        css={[tw`flex ml-28 mt-2 xl:mt-0 w-1/2 justify-between items-stretch`]}
      >
        {rightMenu?.map(({ label, childItems }, i) => {
          return (
            <HeaderMenuItems
              key={i}
              title={label}
              options={childItems.nodes}
              index={i}
              desktop
            />
          )
        })}
        <HeaderMenuRightIcons
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
          handleMobileMenu={handleMobileMenu}
        />
      </div>
    </nav>
  )
}

export default HeaderMenu
