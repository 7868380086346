import DayWindy from "../Icons/Weather/Day/DayWindy"
import MostlySunny from "../Icons/Weather/Day/MostlySunny"
import DayPartlyCloudy from "../Icons/Weather/Day/DayPartlyCloudy"
import Sunny from "../Icons/Weather/Day/Sunny"
import DayWindyCloudy from "../Icons/Weather/Day/DayWindyCloudy"
import DayWindyRain from "../Icons/Weather/Day/DayWindyRain"
import DayWindySnow from "../Icons/Weather/Day/DayWindySnow"
import DayLightRain from "../Icons/Weather/Day/DayLightRain"
import DayModerateRain from "../Icons/Weather/Day/DayModerateRain"
import DayHeavyRain from "../Icons/Weather/Day/DayHeavyRain"
import DaySnow from "../Icons/Weather/Day/DaySnow"
import Clear from "../Icons/Weather/Night/Clear"
import MostlyClear from "../Icons/Weather/Night/MostlyClear"
import NightPartlyCloudy from "../Icons/Weather/Night/NightPartlyCloudy"
import NightWindyCloudy from "../Icons/Weather/Night/NightWindyCloudy"
import NightWindyRain from "../Icons/Weather/Night/NightWindyRain"
import NightWindySnow from "../Icons/Weather/Night/NightWindySnow"
import NightLightRain from "../Icons/Weather/Night/NightLightRain"
import NightModerateRain from "../Icons/Weather/Night/NightModerateRain"
import NightHeavyRain from "../Icons/Weather/Night/NightHeavyRain"
import NightSnow from "../Icons/Weather/Night/NightSnow"

export const weatherIconOptions = [
  {
    id: 1,
    clouds: false,
    cloudDesc: "sunny",
    day: true,
    name: "sunny",
    icon: Sunny,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 2,
    clouds: true,
    cloudDesc: "mostly sunny",
    day: true,
    name: "mostly sunny",
    icon: MostlySunny,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 3,
    clouds: true,
    cloudDesc: "partly cloudy",
    day: true,
    name: "day partly cloudy",
    icon: DayPartlyCloudy,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 4,
    clouds: false,
    cloudDesc: "sunny",
    day: true,
    name: "day windy",
    icon: DayWindy,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: true,
  },
  {
    id: 5,
    clouds: true,
    cloudDesc: null,
    day: true,
    name: "day windy cloudy",
    icon: DayWindyCloudy,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: true,
  },
  {
    id: 6,
    clouds: true,
    cloudDesc: null,
    day: true,
    name: "day windy rainy",
    icon: DayWindyRain,
    rain: true,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: true,
  },
  {
    id: 7,
    clouds: true,
    cloudDesc: null,
    day: true,
    name: "day windy Snowy",
    icon: DayWindySnow,
    rain: false,
    rainDesc: null,
    snow: true,
    snowDesc: null,
    windy: true,
  },
  {
    id: 8,
    clouds: true,
    cloudDesc: null,
    day: true,
    name: "day light rain",
    icon: DayLightRain,
    rain: true,
    rainDesc: "light rain",
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 9,
    clouds: true,
    cloudDesc: null,
    day: true,
    name: "day moderate rain",
    icon: DayModerateRain,
    rain: true,
    rainDesc: "moderate rain",
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 10,
    clouds: true,
    cloudDesc: null,
    day: true,
    name: "day heavy rain",
    icon: DayHeavyRain,
    rain: true,
    rainDesc: "heavy rain",
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 11,
    clouds: true,
    cloudDesc: null,
    day: true,
    name: "day snow",
    icon: DaySnow,
    rain: false,
    rainDesc: null,
    snow: true,
    snowDesc: null,
    windy: false,
  },
  {
    id: 12,
    clouds: false,
    cloudDesc: "sunny",
    day: false,
    name: "clear",
    icon: Clear,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 13,
    clouds: true,
    cloudDesc: "mostly sunny",
    day: false,
    name: "mostly clear",
    icon: MostlyClear,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 14,
    clouds: true,
    cloudDesc: "partly cloudy",
    day: false,
    name: "night partly cloudy",
    icon: NightPartlyCloudy,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 16,
    clouds: true,
    cloudDesc: null,
    day: false,
    name: "night windy cloudy",
    icon: NightWindyCloudy,
    rain: false,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: true,
  },
  {
    id: 17,
    clouds: true,
    cloudDesc: null,
    day: false,
    name: "night windy rainy",
    icon: NightWindyRain,
    rain: true,
    rainDesc: null,
    snow: false,
    snowDesc: null,
    windy: true,
  },
  {
    id: 18,
    clouds: true,
    cloudDesc: null,
    day: false,
    name: "night windy Snowy",
    icon: NightWindySnow,
    rain: false,
    rainDesc: null,
    snow: true,
    snowDesc: null,
    windy: true,
  },
  {
    id: 19,
    clouds: true,
    cloudDesc: null,
    day: false,
    name: "night light rain",
    icon: NightLightRain,
    rain: true,
    rainDesc: "light rain",
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 20,
    clouds: true,
    cloudDesc: null,
    day: false,
    name: "night moderate rain",
    icon: NightModerateRain,
    rain: true,
    rainDesc: "moderate rain",
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 21,
    clouds: true,
    cloudDesc: null,
    day: false,
    name: "night heavy rain",
    icon: NightHeavyRain,
    rain: true,
    rainDesc: "heavy rain",
    snow: false,
    snowDesc: null,
    windy: false,
  },
  {
    id: 22,
    clouds: true,
    cloudDesc: null,
    day: false,
    name: "night snow",
    icon: NightSnow,
    rain: false,
    rainDesc: null,
    snow: true,
    snowDesc: null,
    windy: false,
  },
]

export const getWeatherConditions = result => {
  const { clouds, main, rain, snow, wind, weather } = result

  const cloudPercentage = clouds?.all?.cloudPercentage
  const temp = main?.temp
  const rainVolume = rain && rain["1h"]
  const snowVolume = snow && snow["1h"]
  const snowy = snowVolume && snowVolume > 0
  const windSpeed = wind?.speed?.windSpeed

  const raininess =
    rainVolume > 7.5
      ? "heavy rain"
      : rainVolume > 5
      ? "moderate rain"
      : rainVolume > 0
      ? "light rain"
      : "no rain"

  const cloudiness =
    cloudPercentage > 20
      ? "partly cloudy"
      : cloudPercentage > 20
      ? "mostly sunny"
      : "sunny"

  // day icons look like "01d" whereas night icons look like "01n"
  const day = weather[0].icon.includes("d")

  // 8.94 meters/second is approximately 20mph
  const windy = windSpeed > 8.94 ? true : false

  // Find weather icon that matches weather conditions
  const weatherIcon = weatherIconOptions.find(icon => {
    if (raininess === "no rain" && !snowy) {
      if (windy && day && cloudiness !== "sunny") {
        return icon.clouds && icon.windy && icon.day === day
      } else if (windy && !day && cloudiness === "sunny") {
        return icon.cloudDesc === cloudiness && !icon.windy && !icon.day
      } else {
        return (
          icon.cloudDesc === cloudiness &&
          icon.windy === windy &&
          icon.day === day
        )
      }
    } else if (!snowy) {
      if (windy) {
        return icon.rain && icon.windy && icon.day === day
      } else {
        return (
          icon.rainDesc === raininess &&
          icon.windy === windy &&
          icon.day === day
        )
      }
    } else if (raininess === "no rain") {
      return icon.snow && icon.windy === windy && icon.day === day
    }
  })

  // Convert Celsius to Farenheit + round temp
  const tempInFarenheit = Math.round(temp * (9 / 5) + 32)

  return { tempInFarenheit, weatherIcon }
}
