import React, { useState, useEffect } from "react"
import tw from "twin.macro"

import { Footer } from "../Footer"
import GlobalStyles from "../GlobalStyles/GlobalStyles"
import "../../global.css"
import Header from "../Header/Header"

const Layout: React.FC = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState(false)

  // Close mobileMenu if open when window is resized to desktop view
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.innerWidth >= 1280) {
        setMobileMenu(false)
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  // useEffect(() => {
  //   const gatsbyFocusWrapper = document.getElementById("gatsby-focus-wrapper")
  //   if (gatsbyFocusWrapper) {
  //     gatsbyFocusWrapper.removeAttribute("style")
  //     gatsbyFocusWrapper.removeAttribute("tabIndex")
  //   }
  // }, [])

  return (
    <>
      <GlobalStyles />
      <Header mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      <main
        id="main"
        tabIndex={0}
        css={[mobileMenu && tw`hidden`]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
      >
        {children}
      </main>
      <Footer mobileMenu={mobileMenu} />
    </>
  )
}

export default Layout
