import React, { useState, useContext, useEffect } from "react"
import { Link } from '../Link'
import { CopyrightNavItemProps } from "./Footer.d"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import tw from "twin.macro"

const CopyrightNavItem: React.FC<CopyrightNavItemProps> = ({label, url, ...remainingProps}) => {
    const [text, setText] = useState({label})
      const { language } = useContext(LanguageContext)

    useEffect(() => {
      if (language === "en") {
        setText({label})
      } else {
        ;(async () => {
          const textToTranslate = getStringValuesArray([label])
          const translations = await TranslateClient.translate(
            textToTranslate,
            language
          )
          const translated = mapValuesToObject(text, translations)
          setText(translated)
        })()
      }
    }, [language])

    return (
        <Link 
          to={url} 
          css={[tw`text-xs text-grayBlack font-normal sm:text-sm`, 
            tw`focus:outline-none focus-visible:p-1 focus-visible:border-dashed focus-visible:border focus-visible:border-blue-300 focus-visible:rounded-sm`
          ]} 
          {...remainingProps}
          >
            {text?.label}
        </Link>
    )
}

export default CopyrightNavItem
