import React from "react"

const NightWindySnow: React.FC<{ styling?: any }> = ({ styling }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      css={[styling]}
      fill="#fff"
    >
      <path
        d="M4.23,16.89c0,1.33,0.47,2.48,1.4,3.44s2.07,1.47,3.4,1.53c0.12,0,0.18-0.06,0.18-0.17v-1.34c0-0.11-0.06-0.17-0.18-0.17
	c-0.86-0.05-1.59-0.39-2.19-1.03c-0.6-0.64-0.9-1.39-0.9-2.26c0-0.83,0.28-1.55,0.85-2.17c0.57-0.61,1.27-0.97,2.1-1.07l0.53-0.04
	c0.13,0,0.2-0.06,0.2-0.18l0.07-0.54c0.11-1.08,0.56-1.99,1.37-2.72c0.81-0.73,1.76-1.1,2.85-1.1c1.09,0,2.04,0.37,2.86,1.1
	c0.82,0.73,1.28,1.64,1.4,2.72l0.08,0.57c0,0.12,0.06,0.19,0.17,0.19h1.62c0.91,0,1.68,0.32,2.33,0.95c0.64,0.63,0.97,1.4,0.97,2.28
	c0,0.86-0.3,1.61-0.91,2.25c-0.61,0.64-1.34,0.99-2.19,1.04c-0.12,0-0.19,0.06-0.19,0.17v1.34c0,0.11,0.06,0.17,0.19,0.17
	c1.34-0.04,2.47-0.55,3.4-1.51c0.93-0.97,1.39-2.12,1.39-3.45c0-0.71-0.14-1.38-0.43-2.01c0.79-0.96,1.18-2.07,1.18-3.32
	c0-0.95-0.24-1.83-0.71-2.64s-1.11-1.45-1.92-1.92s-1.68-0.7-2.62-0.7c-1.55,0-2.85,0.58-3.89,1.73c-0.81-0.43-1.71-0.65-2.71-0.65
	c-1.41,0-2.67,0.44-3.76,1.32s-1.8,2-2.11,3.36c-1.11,0.26-2.02,0.84-2.74,1.74C4.59,14.71,4.23,15.74,4.23,16.89z M9.72,24.61
	c0,0.21,0.08,0.4,0.24,0.57c0.18,0.16,0.37,0.24,0.58,0.24c0.24,0,0.43-0.08,0.59-0.23c0.16-0.16,0.23-0.35,0.23-0.58
	c0-0.24-0.08-0.43-0.24-0.59c-0.16-0.16-0.35-0.23-0.59-0.23c-0.23,0-0.43,0.08-0.59,0.23C9.8,24.17,9.72,24.37,9.72,24.61z
	 M10.58,20.98c0,0.24,0.08,0.44,0.24,0.61c0.16,0.17,0.35,0.25,0.59,0.25c0.23,0,0.43-0.08,0.59-0.25c0.16-0.17,0.24-0.37,0.24-0.61
	c0-0.23-0.08-0.42-0.24-0.58c-0.16-0.16-0.35-0.24-0.59-0.24c-0.23,0-0.43,0.08-0.59,0.24C10.66,20.56,10.58,20.76,10.58,20.98z
	 M12.47,26.56c0,0.22,0.08,0.41,0.24,0.57c0.17,0.17,0.36,0.25,0.58,0.25c0.24,0,0.44-0.08,0.6-0.23c0.17-0.16,0.25-0.35,0.25-0.59
	s-0.08-0.44-0.25-0.6c-0.17-0.17-0.37-0.25-0.6-0.25c-0.22,0-0.41,0.08-0.58,0.25C12.56,26.13,12.47,26.33,12.47,26.56z M13.33,22.9
	c0,0.22,0.08,0.42,0.25,0.6c0.16,0.16,0.35,0.24,0.57,0.24c0.24,0,0.44-0.08,0.61-0.24s0.25-0.36,0.25-0.6
	c0-0.23-0.08-0.43-0.25-0.6s-0.37-0.25-0.61-0.25c-0.23,0-0.42,0.08-0.58,0.25S13.33,22.67,13.33,22.9z M13.76,19.3
	c0,0.23,0.08,0.42,0.24,0.58s0.36,0.24,0.58,0.24c0.24,0,0.44-0.08,0.6-0.24c0.17-0.16,0.25-0.35,0.25-0.59
	c0-0.23-0.08-0.43-0.25-0.59s-0.37-0.24-0.6-0.24c-0.22,0-0.42,0.08-0.58,0.24S13.76,19.07,13.76,19.3z M16.13,24.61
	c0,0.21,0.08,0.4,0.23,0.57c0.17,0.16,0.38,0.24,0.6,0.24c0.24,0,0.43-0.08,0.59-0.23c0.16-0.16,0.23-0.35,0.23-0.58
	c0-0.24-0.08-0.43-0.23-0.59c-0.16-0.16-0.35-0.23-0.59-0.23c-0.24,0-0.44,0.08-0.6,0.24C16.21,24.18,16.13,24.38,16.13,24.61z
	 M16.98,20.98c0,0.24,0.08,0.44,0.24,0.61c0.16,0.17,0.36,0.25,0.59,0.25c0.23,0,0.43-0.08,0.59-0.25c0.16-0.17,0.24-0.37,0.24-0.61
	c0-0.23-0.08-0.42-0.24-0.58c-0.16-0.16-0.35-0.24-0.59-0.24c-0.23,0-0.43,0.08-0.59,0.24S16.98,20.76,16.98,20.98z M18.03,9.02
	c0.68-0.68,1.5-1.01,2.48-1.01c0.98,0,1.81,0.35,2.5,1.04s1.03,1.53,1.03,2.52c0,0.59-0.17,1.2-0.51,1.84
	c-0.96-0.96-2.13-1.44-3.5-1.44h-0.31C19.44,10.8,18.87,9.82,18.03,9.02z"
      />
    </svg>
  )
}

export default NightWindySnow
